<template>
    <b-modal
    ref="trackingModal"
    size="lg"
    title="Track App Attendance Password"
    hide-footer
    @hidden="hidden"
>
<b-table
:fields=fields
:items="items"
primary-key="device_id"
show-empty
sticky-header="70vh"
>

</b-table>
    </b-modal>
</template>
<script>
import DashBoardService from "@/views/assistance/views/dashboard/services/dashboard.services.js"
import fields from "@/views/assistance/views/dashboard/data/password-app-tracking-fields.js"
export default{
    data(){
        return{
            items:[],
            fields
        }
    },
    mounted(){
        this.toggleModal("trackingModal");
    
    },
    created(){
        this.getDeviceTracking();
    },
    methods: {
        
        hidden() {
            this.$emit("hidden");
        },
        getDeviceTracking(){

            this.addPreloader();
            DashBoardService.getDeviceTracking().then((response)=>{
                if(response.status==200){
                    this.removePreloader();
                    this.items=response.data.data;
                }
            }).catch((error)=>{
                console.log(error);
            })
        }
    },
    
}
</script>