<template>
  <b-modal
    ref="employees_birthday"
    :size="'xl'"
    title="Employees Birthday"
    hide-footer
    no-close-on-backdrop
    scrollable
    @hidden="hidden"
  >
    <div>
      <filter-slot
        :filter="[]"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :send-multiple-sms="false"
        :change-columns-by-client="true"
        @reload="$refs['refBirthdayList'].refresh()"
      >
        <b-table
          slot="table"
          ref="refBirthdayList"
          no-provider-filtering
          :items="birthdayProvider"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(date_of_birth)="data">
            <span>{{ data.value|myGlobal}}</span>
            </template> 
        </b-table>
      </filter-slot>
    </div>
  </b-modal>
</template>

<script>
import DashboardServices from "@/views/assistance/views/dashboard/services/dashboard.services.js";
export default {
  props: {
    valueFilterDay: null,
    valueFilterWeekStart: null,
    valueFilterWeekEnd: null,
    valueFilterMonth: null,
    valueFilterYear: null,
    valueFilterModule: null,
  },
  mounted() {
    this.toggleModal("employees_birthday");
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      isBusy: false,
      totalRows: 0,
      startPage: null,
      toPage: null,
      sortBy: "",
      sortDesc: true,
      fields: [
        {
          key: "number",
          label: "ID Employee",
          visible: false,
        },
        {
          key: "first_name",
          label: "First Name",
          visible: false,
        },
        {
          key: "last_name",
          label: "Last Name",
          visible: false,
        },
        {
          key: "date_of_birth",
          label: "Date Birthday",
          visible: false,
        },
      ],
      listEmployeesBirthday: [],
      filterDay: false,
      filterWeek: true,
      filterMonth: false,
      filterYear: false,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    hidden() {
      this.$emit("closing");
    },
    async birthdayProvider(ctx) {
      this.showModalBirthday = true;
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sortBy: ctx.sortBy,
          sortOrder: ctx.sortDesc ? "ASC" : "DESC",
          orderBy: 10,
          order: "DESC",
          module_id: this.valueFilterModule,
          user_id: null,
          filterDay: this.valueFilterDay,
          filterWeekStart: this.valueFilterWeekStart,
          filterWeekEnd: this.valueFilterWeekEnd,
          filterMonth: this.valueFilterMonth,
          filterYear: this.valueFilterYear,
        };
        const data = await DashboardServices.getListEmployeesBirthday(params);
        if (data.status === 200) {
          this.listEmployeesBirthday = data.data.data;
          this.totalRows = data.data.total;
          this.startPage = data.data.from || 0;
          this.toPage = data.data.to || 0;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;
          return this.listEmployeesBirthday || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },
  },
};
</script>
