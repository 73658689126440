<template>
<b-modal
ref="changeModal"
size="sm"
title="Change App Attendance Password"
hide-footer
@hidden="hidden"
>
<validation-observer ref="formPasswordObserver">

<b-form-group>
  <b-form-label>Password</b-form-label>
  <validation-provider rules="required" v-slot="{errors}">
    <b-form-input
    :state="errors[0] ? false : null"
    v-model="passwordChanged"
    type="text"
  >
    

  </b-form-input> 
  </validation-provider>
  <b-button variant="primary" @click="changePassword">Change Password</b-button>
  </b-form-group> 
 
</validation-observer>
</b-modal>
</template>
<script>
import DashBoardService from "@/views/assistance/views/dashboard/services/dashboard.services.js"
export default {
  data() {
    return {
      passwordChanged: null,
    };
  },
  mounted(){
    this.toggleModal("changeModal");
  },
  methods: {
    hidden() {
      this.$emit("hidden");
    },
    changePassword(){
      this.$refs.formPasswordObserver.validate().then(valid => {
    if (valid) {
      const params={
        password: this.passwordChanged,
        passwordlength: this.passwordChanged.length

      }
      this.addPreloader();

      DashBoardService.setAppPassowrd(params).then((response)=>{
        if(response.status==200){
          this.$refs.changeModal.hide();
          this.removePreloader();
          this.showSuccessSwal(response.data.message);
        }
      }).catch((error)=>{
        console.log(error);
        this.removePreloader();
        this.showErrorSwal(error);

      }
      )
      // Aquí puedes realizar acciones adicionales, como enviar la contraseña al servidor
    } 
  })
  
    }}
}
</script>