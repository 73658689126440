<template>
  <div>
    <div>
      <h3 class="text-center">
        Justifications Pending Approval
      </h3>
    </div>
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :no-visible-principal-filter="dataEmployee == null ? false : true"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :change-columns-by-client="true"
      @reload="$refs['refOvertimeApproved'].refresh()"
    >
      <b-table
        slot="table"
        ref="refOvertimeApproved"
        :items="overtimeApprovedPendingRRhhManagement"
        :fields="visibleFields"
        primary-key="id"
        table-class="text-nowrap"
        sticky-header="45vh"
        show-empty
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(employee_name)="data">
          <div class="d-flex justify-content-center">
            <span>
              {{ data.item.employee_name }}
            </span>
          </div>
        </template>
        <template #cell(overtime)="data">
          <div class="d-flex justify-content-center">
            <span
              class="text-success"
              style="padding: 0 10px; border-radius: 5px"
            >
              {{ data.item.overtime }}
            </span>
          </div>
        </template>
        <template #cell(date_mark)="data">
          <div v-if="data.item.date_mark">
            <div
              v-for="(date, index) in JSON.parse(data.item.date_mark)"
              v-show="showAll || index < 3"
              :key="index"
            >
              <span>{{ date | myGlobal }}</span>
            </div>
            <div v-if="!showAll && JSON.parse(data.item.date_mark).length > 3">
              <a
                style="cursor: pointer"
                href="#"
                @click="showAll = true"
              >See more...</a>
            </div>
            <div v-if="showAll && JSON.parse(data.item.date_mark).length > 3">
              <a
                style="cursor: pointer"
                href="#"
                @click="showAll = false"
              >See less...</a>
            </div>
          </div>
        </template>
        <template #cell(justification)="data">
          <div
            v-if="data.item.type_justification === null"
            class="w-100 text-center"
          >
            Not justificated hours
          </div>
          <div v-else>
            <div
              v-if="
                !['Remote', 'Other'].includes(data.item.name_type_justification)
              "
              class="d-flex justify-content-center"
            >
              <span
                style="text-align: center; cursor: pointer"
                @click="
                  data.item.type_justification === 4
                    ? showMedicalRest(data.item)
                    : showJustification(data.item)
                "
              >
                <tabler-icon
                  v-if="data.item.type_justification != 5"
                  icon="FileIcon"
                  size="21"
                  badge-classes="bg-primary"
                />
                <br>
                {{ data.item.name_type_justification }}
              </span>
            </div>
            <div
              v-else
              class="d-flex justify-content-center"
            >
              <span style="text-align: center">
                {{ data.item.observation }}
              </span>
            </div>
          </div>
        </template>
        <template #cell(tracking)="data">
          <!-- tabler-icon -->
          <div class="d-flex justify-content-center">
            <tabler-icon
              icon="AlignLeftIcon"
              size="20"
              class="cursor-pointer text-primary"
              @click="showModalTracking(data.item.id_justificationb)"
            />
          </div>
        </template>
        <template #cell(process_overtime)="data">
          <div class="d-flex justify-content-center">
            <b-badge
              :variant="returnProcessOvertimeColor(data.item.process_overtime)"
            >
              {{ data.item.process_overtime_description }}
            </b-badge>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-form-group>
            <b-form-checkbox
              v-model="data.item.validated"
              checked="1"
              value="1"
              @change="
                onChangeCheck(data.item.validated, data.item, data.index)
              "
            >Validate</b-form-checkbox>
            <b-form-checkbox
              v-model="data.item.validated"
              checked="2"
              value="2"
              @change="
                onChangeCheck(data.item.validated, data.item, data.index)
              "
            >Not validate</b-form-checkbox>
          </b-form-group>
        </template>
        <template #cell(observation)="data">
          <div class="d-flex justify-content-center">
            <!-- disabled if the hour is validated, if not, it is enabled -->
            <b-form-textarea
              v-model="data.item.motive"
              size="sm"
              :disabled="
                data.item.validated == 1 ||
                  data.item.validated == null ||
                  data.item.validated == 0
              "
              placeholder="Write a motive for disapproving these hours"
            />
          </div>
        </template>
        <template #custom-foot>
          <b-tr class="bg-dark">
            <b-th />
            <b-th />
            <b-th />
            <b-th />
            <b-th />
            <b-th class="footer-dark">
              <div class="footer-span checkbox">
                <span class="font-small-3">
                  <label class="checkbox-container">
                    <input
                      id="validate-checkbox"
                      v-model="validateAll"
                      type="checkbox"
                      class="custom-checkbox blue"
                      style="display: none"
                      @change="markCheckboxAll()"
                    >
                    <span class="checkmark" />
                    <span style="color: black">Validate All</span>
                  </label>
                </span>
              </div>
              <br>
              <div class="footer-span checkbox">
                <span class="font-small-3">
                  <label class="checkbox-container">
                    <input
                      id="validate-checkbox"
                      v-model="notValidateAll"
                      type="checkbox"
                      class="custom-checkbox blue"
                      style="display: none"
                      @change="markCheckboxAllNotValidate()"
                    >
                    <span class="checkmark" />
                    <span style="color: black">Not Validate All</span>
                  </label>
                </span>
              </div>
            </b-th>
            <b-th />
          </b-tr>
        </template>
      </b-table>
    </filter-slot>
    <modal-tracking-medical-rest
      v-if="showTracking"
      :id-justification="idJustification"
      @closeModal="closeTrackingMedicalRest"
    />
    <modal-justification
      v-if="showUpload"
      :employee_id="userIdSelected"
      :data-justification="dataJustification"
      :action="actionJustification"
      @hidden="closeModalJustification"
      @refreshData="refreshTable"
    />
    <modal-upload-medical-rest
      v-if="showUploadMedicalRest"
      :employee_id="userIdSelected"
      :data-justification="dataJustification"
      :action="'show'"
      @closeModal="closeUploadMedicalRest"
      @refreshData="refreshTable"
    />
  </div>
</template>

<script>
import DashboardServices from '@/views/assistance/views/dashboard/services/dashboard.services';
import ModalJustification from '@/views/assistance/views/dashboard/components/ModalJustification.vue';
import ModalTrackingMedicalRest from '@/views/assistance/views/dashboard/components/medicalRest/modalTrackingMedicalRest.vue';
import ModalUploadMedicalRest from '@/views/assistance/views/dashboard/components/medicalRest/modalUploadMedicalRest.vue';
import moment from 'moment';

import { mapGetters } from 'vuex';

export default {
  components: {
    ModalJustification,
    ModalTrackingMedicalRest,
    ModalUploadMedicalRest,
  },
  props: {
    dataEmployee: null,
    valueFilterDay: null,
    valueFilterWeekStart: null,
    valueFilterWeekEnd: null,
    valueFilterMonth: null,
    valueFilterYear: null,
  },
  computed: {
    visibleFields() {
      return this.fieldsApproved.filter(field => field.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    isRRhh() {
      return this.moduleId === 17;
    },
    isManagement() {
      return this.moduleId === 16;
    },
    asChief() {
      // If is rrhh or management and tabRole is chief will access as chief role, in the other modules that are not rrhh or management will access as chief too
      return (
        ((this.isRRhh || this.isManagement)
          && this.$route.meta.tabRole === 'chief')
        || this.isNotRRHHorManagement
      );
    },
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      isBusy: false,
      totalRows: 0,
      startPage: null,
      toPage: null,
      sortBy: '',
      sortDesc: true,
      fieldsApproved: [
        {
          key: 'employee_name',
          label: 'Employee',
          thClass: 'text-center',
          visible: true,
        },
        {
          key: 'date_mark',
          label: 'Date',
          thClass: 'text-center',
          visible: true,
        },
        {
          key: 'overtime',
          label: 'Overtime',
          thClass: 'text-center',
          visible: true,
        },
        {
          key: 'justification',
          label: 'Justification',
          thClass: 'text-center',
          visible: true,
        },
        {
          key: 'tracking',
          label: 'tracking',
          thClass: 'text-center',
          visible: true,
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'text-center',
          visible: true,
        },
        {
          key: 'observation',
          label: 'Motive',
          thClass: 'text-center',
          visible: true,
        },
      ],
      listApprovedOvertime: [],
      showUpload: false,
      showTracking: false,
      showAll: false,
      dataValidated: [],
      showUploadMedicalRest: false,
      dataJustification: {},
      actionJustification: null,
      validateAll: false,
      userIdSelected: null,
      notValidateAll: false,
    };
  },
  watch: {
    'dataEmployee.user_id': function () {
      this.userIdSelected = this.dataEmployee.user_id;
    },
  },

  mounted() {
    this.fieldsApproved.findIndex(
      field => field.key === 'process_overtime',
    );

    this.userIdSelected = this.dataEmployee.user_id;
  },

  methods: {
    async closeModalJustification() {
      this.showUpload = false;
    },
    refreshTable() {
      this.$refs.refOvertimeApproved.refresh();
    },
    closeTrackingMedicalRest() {
      this.showTracking = false;
    },
    showModalTracking(idJustification) {
      this.idJustification = idJustification;
      this.showTracking = true;
    },
    async overtimeApprovedPendingRRhhManagement(ctx) {
      const userId = this.dataEmployee ? this.dataEmployee.user_id : null;
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sortBy: ctx.sortBy,
          sortOrder: ctx.sortDesc ? 'ASC' : 'DESC',
          orderBy: 10,
          order: 'DESC',
          user_id: userId,
          filterDay: this.dataEmployee != null ? this.valueFilterDay : null,
          filterWeekStart:
            this.dataEmployee != null ? this.valueFilterWeekStart : null,
          filterWeekEnd:
            this.dataEmployee != null ? this.valueFilterWeekEnd : null,
          // obtener mes actual
          filterMonth: this.dataEmployee != null
            ? this.valueFilterMonth
            : ((this.isRRhh || this.isManagement) && moment().date() <= 5
              ? moment().subtract(1, 'month').format('MM')
              : moment().format('MM')),
          filterYear:
            this.dataEmployee != null
              ? this.valueFilterYear
              : moment().format('YYYY'),
          statusForRrhhManagement: 1, // pending,
          moduleId: this.moduleId,
        };
        const data = await DashboardServices.getListPendingApprovedOvertimeRRHhManagement(
          params,
        );
        if (data.status === 200) {
          this.listApprovedOvertime = data.data.data;
          this.totalRows = data.data.total;
          this.startPage = data.data.from || 0;
          this.toPage = data.data.to || 0;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;
          this.listApprovedOvertime.showAll = false;
          this.perPage = 10;
          this.listApprovedOvertime.forEach(item => {
            this.$set(item, 'validated', 0);
            this.$set(item, 'motive', null);
          });
          return this.listApprovedOvertime || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
      return [];
    },
    returnProcessOvertimeColor(processOvertimeNum) {
      if (processOvertimeNum === 2) {
        return 'light-warning';
      }
      if ([4, 6].includes(processOvertimeNum)) {
        return 'light-success';
      }
      return 'light-danger';
    },
    addValidatedToArray(item, validateStatement) {
      this.dataValidated.push({
        id_justification: item.id_justificationb,
        state: validateStatement,
      });
    },
    deleteValidatedFromArray(index) {
      this.dataValidated.splice(index, 1);
    },
    async confirmHoursRRhhManagement() {
      this.dataValidated = [];
      this.listApprovedOvertime.forEach(item => {
        if (item.validated === '1' || item.validated === 1) {
          this.dataValidated.push({
            id_justification: item.id_justificationb,
            state: 'validate',
            motive: null,
          });
        } else if (item.validated === '2' || item.validated === 2) {
          this.dataValidated.push({
            id_justification: item.id_justificationb,
            state: 'not-validate',
            motive: item.motive,
          });
        }
      });
      try {
        this.addPreloader();
        const params = {
          data: this.dataValidated,
          user_id: this.currentUser.user_id,
          module_id: this.moduleId,
        };
        const response = await DashboardServices.approveDisapproveRRhhManagement(params);
        if (response.status === 200) {
          this.showSuccessSwal('Success', 'Overtime Approved Successfully');
          this.dataValidated = [];
          await this.refreshTable();
          this.$emit('refreshTable');
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },
    markCheckboxAll() {
      // 0: not marked checkbox
      // 1: marked checkbox for approving
      // 2: marked checkbox for disapproving
      if (this.validateAll === true) {
        this.listApprovedOvertime.forEach(item => {
          this.$set(item, 'validated', 1);
        });
        this.notValidateAll = false;
      } else {
        this.listApprovedOvertime.forEach(item => {
          this.$set(item, 'validated', 0);
          // this.deleteValidatedFromArray(index)
        });
      }
    },
    markCheckboxAllNotValidate() {
      if (this.notValidateAll === true) {
        this.listApprovedOvertime.forEach(item => {
          this.$set(item, 'validated', 2);
        });
        this.validateAll = false;
      } else {
        this.listApprovedOvertime.forEach(item => {
          this.$set(item, 'validated', 0);
        });
      }
    },
    showMedicalRest(data) {
      this.showUploadMedicalRest = true;
      this.dataJustification = data;
      this.dataJustification.id = data.id_justificationb;
      this.userIdSelected = data.employee_id;
    },
    showJustification(data) {
      if (data.type_justification === 5) {
        return;
      }
      this.showUpload = true;
      this.dataJustification = data;
      this.userIdSelected = data.employee_id;
      this.actionJustification = 'Show';
    },
    closeUploadMedicalRest() {
      this.showUploadMedicalRest = false;
    },
  },
};
</script>
