export default [
    {
        key: "date_mark",
        label: "Date",
        class: "text-center",
    },

    {
        key: "first_mark",
        label: "First Mark",
        visible: false,
    },
    {
        key: "second_mark",
        label: "Second Mark",
        visible: false,
    },
    {
        key: "third_mark",
        label: "Third Mark",
        visible: false,
    },
    {
        key: "fourth_mark",
        label: "Fourth Mark",
        visible: false,
    },
    // {
    //     key: "last_mark",
    //     label: "Last Mark",
    //     visible: false,
    // },
    {
        key: "working_hours",
        label: "Working Hours",
        visible: false,
    },
    // {
    //     key: "first_mark_rounded",
    //     label: "First Mark Rounded",
    //     visible: false,
    // },
    // {
    //     key: "last_mark_rounded",
    //     label: "Last Mark Rounded",
    //     visible: false,
    // },
    {
        key: "working_hours_rounded",
        label: "Rounded",
        visible: false,
    },
    {
        key: "missing_hours",
        label: "Missing Hours",
        visible: false,
    },
    {
        key: "overtime",
        label: "Overtime",
        visible: false,
    },
];
