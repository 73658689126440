<template>
  <b-modal
    ref="modal_add_marks"
    title="Added Marks Tracking"
    size="lg"
    :hide-footer="true"
    @hidden="close"
  >
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginateP"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs.refMarksList.refresh()"
    >
      <template #table>
        <b-table
          ref="refMarksList"
          :items="marksProvider"
          :fields="fields"
          :current-page="paginateP.currentPage"
          :per-page="paginateP.perPage"
          show-empty
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(mark_time)="data">
            <span>{{ data.value | myGlobalWithHour }}</span>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>
<script>
import fields from "@/views/assistance/views/dashboard/data/fields-add-marks-tracking";
import dashboardServices from "@/views/assistance/views/dashboard/services/dashboard.services";
import { mapGetters } from "vuex";

export default {
  props:{
    mng_report:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      fields,
      items: [],
      totalRows: 0,
      paginateP: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 1,
      toPage: 1,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by name",
        model: "",
      },
    };
  },
  methods: {
    close() {
      this.$emit("hidden");
    },
    async marksProvider() {
      const params = {
        user_id: this.currentUser.user_id,
        perpage: this.paginateP.perPage,
        page: this.paginateP.currentPage,
        search_text: this.filterPrincipal.model,
        mng_report:this.mng_report
      };

      const data = await dashboardServices.getMarksTracking(params);
      if (data.status === 200) {
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
        this.startPage = data.data.from;
        this.paginateP.currentPage = data.data.current_page;
        this.paginateP.perPage = data.data.per_page;
  
        return data.data.data;
      }
      this.isBusy = false;
    },
  },
  mounted() {
    this.toggleModal("modal_add_marks");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>