/* eslint-disable indent */
/* eslint-disable quotes */
export default [
    {
        key: "employee_name",
        label: "Employee",
        visible: true,
    },
    {
        key: "mark_time",
        label: "Mark Time",
        visible: true,
    },
    {
        key: "created_by",
        label: "Created By",
        visible: true,
    },
    {
        key: "created_at",
        label: "Created At",
        visible: true,
    },
];
