<template>
  <div class="d-flex">

    <b-button
      class="customized-button left-item"
      @click="openChangePasswordModal"
    >
      <span>Change Password</span>
    </b-button>
    <b-button
      class="customized-button right-item"
      @click="openTrackingPasswordModal"
    >
      <feather-icon
        icon="MenuIcon"
        size="20"
      />
    </b-button>

    <password-change-modal
      v-if="openChangeModal"
      @hidden="closeChangePasswordModal"
    />
    <password-app-tracking
      v-if="openTrackingModal"
      @hidden="closeTrackingPasswordModal"
    />
  </div>

</template>
<script>
import PasswordChangeModal from '@/views/assistance/views/dashboard/components/PasswordChangeModal.vue';
import PasswordAppTracking from '@/views/assistance/views/dashboard/components/PasswordAppTracking.vue';

export default {
  components: {
    PasswordChangeModal,
    PasswordAppTracking,
  },

  data() {
    return {
      openChangeModal: false,
      openTrackingModal: false,
    };
  },
  methods: {
    openChangePasswordModal() {
      this.openChangeModal = true;
    },
    closeChangePasswordModal() {
      this.openChangeModal = false;
    },
    openTrackingPasswordModal() {
      this.openTrackingModal = true;
    },
    closeTrackingPasswordModal() {
      this.openTrackingModal = false;
    },
  },
};
</script>
<style scoped>
.customized-button.left-item {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-right: 0.5em;
}
.customized-button.right-item  {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding:0.8em 1.0em !important;
}
.customized-button span {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 18px;
  text-align: center;
}
.customized-button {
  background-color: #00E396 !important;
  border: 0px !important;
  border-radius: 7px !important;
  padding:0.8em 1.0em  !important;
}
</style>
