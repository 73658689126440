<template>
  <div>

      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <!-- <strong style="font-size: 20px">Marks by employees</strong> -->
        </b-col>
        <b-col
          cols="12"
          class="pt-3"
        >
          <vue-apex-charts
            :key="key"
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
          />
        </b-col>
      </b-row>
    <!-- marks details -->
    <modal-details
      v-if="showModalDetails"
      :employee-id="employeeId"
      :name-employee="nameEmployeeSelected"
      :filters="filters"
      @closeModal="closeDetails"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import modalDetails from '@/views/assistance/views/dashboard/components/graphicMarks/Modals/ModalDetailsMarks.vue';
import ECharts from 'vue-echarts';
import theme from '@/views/social-network/views/dashboard2/components/chard/theme.json';

ECharts.registerTheme('theme-color', theme);

export default {
  components: {
    VueApexCharts,
    modalDetails,
  },
  props: {
    // This property must be equal to the variable that is in the GraphicReport.vue component.
    attendance: {
      type: Array,
      require: true,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      nameEmployeeSelected: null,
      employeeId: null,
      showModalDetails: false,
      key: 1,
      optionsEmployeesId: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 500,
          stacked: true,
          toolbar: {
            show: false,
          },
          fontFamily: 'Montserrat',
          zoom: {
            enabled: true,
          },
          events: {
            click: (event, chartContext, config) => {
              // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
              if (config.dataPointIndex >= 0) {
                const [id, name] = this.optionsEmployeesId[config.dataPointIndex];
                this.employeeId = id;
                this.nameEmployeeSelected = name;
                this.showModalDetails = true;
              }
            },
            dataPointMouseEnter: (event, chartContext) => {
              const tooltip = chartContext.el.querySelector('.apexcharts-tooltip');
              const tooltipTitle = chartContext.el.querySelector(
                '.apexcharts-tooltip-title',
              );
              const color = this.isDarkSkin ? '#F7F7F7' : '#000000';
              const backgroundColor = this.isDarkSkin ? '#3A3A3A' : '#ffffff';
              if (tooltip) {
                tooltip.style.backgroundColor = backgroundColor;
                tooltip.style.color = color;
                tooltip.style.border = '1px solid #000000';
              }
              if (tooltipTitle) {
                tooltipTitle.style.backgroundColor = backgroundColor;
                tooltipTitle.style.color = color;
                tooltipTitle.style.borderBottom = '1px solid #000000';
              }
            },
          },
        },

        tooltip: {
          enabled: true,
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6D7377',
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          type: 'date',
          categories: ['asd', '123'],
          labels: {
            show: true,
            style: {
              colors: '#6e6b7b',
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: '#6e6b7b',
            },
          },
        },
        legend: {
          position: 'top',
          offsetX: -20,
          labels: { colors: true },
        },
        fill: {
          opacity: 1,
        },
      },
      series: [
        {
          name: 'Count Marks',
          data: [1, 3],
        },
      ],
    };
  },
  watch: {
    skin(oldVal, newVal) {
      if (newVal === 'light') {
        this.chartOptions.xaxis.labels.style.colors = '#d8d8d8';
        this.chartOptions.yaxis.labels.style.colors = '#d8d8d8';
      } else {
        this.chartOptions.xaxis.labels.style.colors = '#6e6b7b';
        this.chartOptions.yaxis.labels.style.colors = '#6e6b7b';
      }
      this.keyChart += 1;
      const color = this.isDarkSkin ? '#F7F7F7' : '#000000';
      const backgroundColor = this.isDarkSkin ? '#3A3A3A' : '#ffffff';
      const options = this.chartOptions;
      options.chart.events = {
        dataPointMouseEnter: (event, chartContext) => {
          const tooltip = chartContext.el.querySelector('.apexcharts-tooltip');
          const tooltipTitle = chartContext.el.querySelector(
            '.apexcharts-tooltip-title',
          );
          if (tooltip) {
            tooltip.style.backgroundColor = backgroundColor;
            tooltip.style.color = color;
            tooltip.style.border = '1px solid #000000';
          }
          if (tooltipTitle) {
            tooltipTitle.style.backgroundColor = backgroundColor;
            tooltipTitle.style.color = color;
            tooltipTitle.style.borderBottom = '1px solid #000000';
          }
        },
      };
    },
  },
  mounted() {
    if (this.isDarkSkin) {
      this.chartOptions.xaxis.labels.style.colors = 'white';
      this.chartOptions.yaxis.labels.style.colors = 'white';
    } else {
      this.chartOptions.xaxis.labels.style.colors = 'black';
      this.chartOptions.yaxis.labels.style.colors = 'black';
    }

    const data = this.attendance;
    this.optionsEmployeesId = data?.map(item => [item.user_id, item.employee]);
    this.chartOptions.xaxis.categories = data?.map(item => item.employee);
    this.series[0].data = data?.map(item => item.num_marks);
    this.key += 1;
  },
  methods: {
    closeDetails() {
      this.showModalDetails = false;
    },
  },
};
</script>
