<template>
  <b-col
    cols="12"
    sm="1"
    class="position-fixed is-mobile"
    style="z-index: 5; top: 15%; min-width: 100px; right: 0px"
  >
    <b-row>
      <b-col cols="12">
        <b-card
          class="bg-danger mi-element"
          style="cursor: pointer; width: 150px; margin-bottom: 0px"
          @click="openListEmployeesAbsence"
        >
          <label
            for=""
            class="font-weight-bolder text-white d-none label"
            style="font-size: 15px; cursor: pointer"
            >Abscences
          </label>
          <div
            class="center"
            style="
              background-color: #ffffff !important;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: start;
              padding: 3px;
            "
          >
            <tabler-icon size="20" icon="XIcon" color="red" />
            <label
              class="mb-0 ml-1"
              style="
                font-size: 15px;
                cursor: pointer;
                color: black;
                margin-bottom: 0;
              "
            >
              {{ indicators.employees_absence }}
            </label>
          </div>
        </b-card>
        <b-card
          class="border border-warning border-3 text-center bg-warning bg-transparent mi-element"
          style="cursor: pointer; width: 150px; margin-bottom: 0px"
          @click="openListEmployeesInconsistencies"
        >
          <label
            for=""
            class="font-weight-bolder text-white d-none label"
            style="font-size: 15px; cursor: pointer"
            >Inconsistencies
          </label>
          <div
            class="center"
            style="
              background-color: #ffffff !important;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: start;
              margin: auto;
              padding: 3px;
            "
          >
            <tabler-icon size="20" icon="QuestionMarkIcon" color="orange" />
            <label
              class="mb-0 ml-1"
              style="
                font-size: 15px;
                cursor: pointer;
                color: black;
                margin-bottom: 0;
              "
            >
              {{ indicators.employees_incosistencies }}
            </label>
          </div>
        </b-card>
        <b-card
          style="width: 150px; margin-bottom: 0px"
          class="border-3 text-center bg-success mi-element"
          @click="openListEmployeesBirthday"
        >
          <label
            for=""
            class="font-weight-bolder text-white d-none label"
            style="font-size: 15px; cursor: pointer"
            >Birthday
          </label>
          <div
            class="center"
            style="
              background-color: #ffffff !important;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: start;
              padding: 3px;
            "
          >
            <tabler-icon size="20" icon="TableIcon" color="green" />
            <label
              class="mb-0 ml-1"
              style="font-size: 15px; cursor: pointer; color: black"
            >
              {{ indicators.birthday_employees }}
            </label>
          </div>
        </b-card>
        <b-card
          style="width: 150px; margin-bottom: 0px"
          class="text-center bg-info mi-element"
          @click="openListEmployeesMedicalRest"
        >
          <label
            for=""
            class="font-weight-bolder text-white d-none label"
            style="font-size: 15px; cursor: pointer"
            >Medical Rest
          </label>
          <div
            class="center"
            style="
              background-color: #ffffff !important;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: start;
              padding: 3px;
            "
          >
            <tabler-icon size="20" icon="CrosshairIcon" color="purple" />
            <label
              class="mb-0 ml-1"
              style="font-size: 15px; cursor: pointer; color: black"
            >
              {{ indicators.medical_rest }}
            </label>
          </div>
        </b-card>
        <div style="height: 80px"></div>
        <b-card
            style="width: 150px; margin-bottom: 0px"
            class="text-center bg-primary mi-element"
            @click="openListEmployeesLate"
        >
          <label
              for=""
              class="font-weight-bolder text-white d-none label"
              style="font-size: 15px; cursor: pointer"
          >Lateness
          </label>
          <div
              class="center"
              style="
              background-color: #ffffff !important;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: start;
              padding: 3px;
            "
          >
            <tabler-icon size="20" icon="CrosshairIcon" color="purple" />
            <label
                class="mb-0 ml-1"
                style="font-size: 15px; cursor: pointer; color: black"
            >
              {{ indicators.lateness_indicator }}
            </label>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <ModalEmployeesAbsence
      v-if="showModalAbsence"
      :valueFilterDay="valueFilterDay"
      :valueFilterMonth="valueFilterMonth"
      :valueFilterYear="valueFilterYear"
      :valueFilterWeekStart="valueFilterWeekStart"
      :valueFilterWeekEnd="valueFilterWeekEnd"
      :valueFilterModule="valueFilterModule"
      @closing="closeModalEmployeesAbsence"
    />
    <ModalEmployeesInconsistencies
      v-if="showModalIncosistencies"
      :valueFilterDay="valueFilterDay"
      :valueFilterMonth="valueFilterMonth"
      :valueFilterYear="valueFilterYear"
      :valueFilterWeekStart="valueFilterWeekStart"
      :valueFilterWeekEnd="valueFilterWeekEnd"
      :valueFilterModule="valueFilterModule"
      @closing="closeModalEmployeesIncosistencies"
    />
    <ModalEmployeesBirthday
      v-if="showModalBirthday"
      :valueFilterDay="valueFilterDay"
      :valueFilterMonth="valueFilterMonth"
      :valueFilterYear="valueFilterYear"
      :valueFilterWeekStart="valueFilterWeekStart"
      :valueFilterWeekEnd="valueFilterWeekEnd"
      :valueFilterModule="valueFilterModule"
      @closing="closeModalEmployeesBirthday"
    />
    <ModalEmployeesMedicalRest
      v-if="showModalMedicalRest"
      :valueFilterDay="valueFilterDay"
      :valueFilterMonth="valueFilterMonth"
      :valueFilterYear="valueFilterYear"
      :valueFilterWeekStart="valueFilterWeekStart"
      :valueFilterWeekEnd="valueFilterWeekEnd"
      :valueFilterModule="valueFilterModule"
      @closeModal="closeModalEmployeesMedicalRest"
    />
    <modal-lateness
        v-if="showModalLate"
        :valueFilterDay="valueFilterDay"
        :valueFilterMonth="valueFilterMonth"
        :valueFilterYear="valueFilterYear"
        :valueFilterWeekStart="valueFilterWeekStart"
        :valueFilterWeekEnd="valueFilterWeekEnd"
        :valueFilterModule="valueFilterModule"
        @closeModal="closeModalEmployeesLate"
    />
  </b-col>
</template>


<script>
import moment from "moment";
import ModalEmployeesAbsence from "@/views/assistance/views/dashboard/components/ModalEmployeesAbsence.vue";
import ModalEmployeesInconsistencies from "@/views/assistance/views/dashboard/components/ModalEmployeesInconsistencies.vue";
import ModalEmployeesBirthday from "@/views/assistance/views/dashboard/components/ModalEmployeesBirthday.vue";
import DashboardServices from "@/views/assistance/views/dashboard/services/dashboard.services.js";
import ModalEmployeesMedicalRest from "@/views/assistance/views/dashboard/components/medicalRest/modalMedicalRest.vue";
import ModalLateness from "@/views/assistance/views/dashboard/components/ModaLateness.vue";

export default {
  components: {
    ModalEmployeesAbsence,
    ModalEmployeesInconsistencies,
    ModalEmployeesBirthday,
    ModalEmployeesMedicalRest,
    ModalLateness,
  },
  props: {
    valueFilterDay: null,
    valueFilterWeekStart: null,
    valueFilterWeekEnd: null,
    valueFilterMonth: null,
    valueFilterYear: null,
    valueFilterModule: null,
    isReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      indicators: [],
      showModalAbsence: false,
      showModalIncosistencies: false,
      showModalBirthday: false,
      showModalMedicalRest: false,
      showModalLate: false,
    };
  },
  computed: {
    currentDate() {
      return moment().format("ddd") + ", " + moment().format("DD MMMM YYYY");
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isRRhh() {
      return this.moduleId === 17;
    },
    isManagement() {
      return this.moduleId === 16;
    },
  },
  async mounted() {
    await this.getIndicators();
  },
  methods: {
    closeModalEmployeesAbsence() {
      this.showModalAbsence = false;
    },
    openListEmployeesAbsence() {
      this.showModalAbsence = true;
    },
    closeModalEmployeesIncosistencies() {
      this.showModalIncosistencies = false;
    },
    openListEmployeesInconsistencies() {
      this.showModalIncosistencies = true;
    },
    closeModalEmployeesBirthday() {
      this.showModalBirthday = false;
    },
    openListEmployeesBirthday() {
      this.showModalBirthday = true;
    },
    closeModalEmployeesMedicalRest() {
      this.showModalMedicalRest = false;
    },
    closeModalEmployeesLate() {
      this.showModalLate = false;
    },
    openListEmployeesMedicalRest() {
      this.showModalMedicalRest = true;
    },
    openListEmployeesLate() {
      this.showModalLate = true;
    },
    async getIndicators() {
      try {
        const params = {
          module_id:
            this.isRRhh || this.isManagement
              ? this.valueFilterModule
              : this.moduleId,
          user_id: null,
          filterDay: this.valueFilterDay,
          filterWeekStart: this.valueFilterWeekStart,
          filterWeekEnd: this.valueFilterWeekEnd,
          filterMonth: this.valueFilterMonth,
          filterYear: this.valueFilterYear,
        };
        const data = await DashboardServices.getIndicatorsAttendance(params);
        if (data.status === 200) {
          this.indicators = data.data.data[0];
          return this.indicators;
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
.mi-element {
  border-radius: 10px 0px 0px 10px !important;
  transform: translateX(0px) !important;
  transition: 0.5s ease-in-out;
  box-shadow: 0px 3px 5px 2px #969595;
}
.mi-element:hover {
  /* min-width: 250px !important; */
  transform: translateX(-40px) !important;
  z-index: 9;
}
.mi-element:hover .label {
  display: block !important;
}
@media screen and (min-width: 1268px) {
  .mi-element {
    transform: translateX(50px) !important;
  }
  .mi-element:hover {
    transform: translateX(0px) !important;
  }
}
@media screen and (max-width: 576px) {
  .is-mobile {
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }
  .is-mobile .mi-element {
    width: 290px !important;
    margin: 20px 5px !important;
    box-shadow: 0px 5px 5px white !important;
    border-radius: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mi-element .label {
    display: flex !important;
    justify-content: center !important;
    padding: 10px 0px !important;
    font-size: 18px !important;
  }
  .mi-element .center {
    display: flex !important;
    justify-content: center !important;
    width: 100px !important;
  }
  .mi-element:hover {
    transform: translateX(0px) !important;
  }
  .mi-element:hover .label {
    display: flex !important;
    justify-content: center !important;
  }
}
</style>
