<template>
  <b-modal
    ref="employee_overtime"
    v-model="show"
    :size="'xl'"
    :title="'Validate hours '"
    hide-footer
    no-close-on-backdrop
    scrollable
    @hidden="hidden"
  >
    <div style="width: 100%">
      <div class="d-flex justify-content-lg-end justify-content-center mt-1 mb-1 mt-lg-0 mb-lg-0">
        <b-button
          v-b-tooltip.hover.top="
            'Rejected justifications by RRHH, management or by chief'
          "
          variant="outline-danger"
          @click="openRejectedJustificationsModal()"
          class="float-right mr-1"
          style="margin-top: 5px"
        >
          <feather-icon icon="AlertTriangleIcon" /> Rejected hours
        </b-button>
        <b-button
          v-if="asChief"
          variant="outline-success"
          @click="addOvertime()"
          class="float-right"
          style="margin-top: 5px"
        >
          <tabler-icon icon="ClockIcon" size="18" /> Add Hours
        </b-button>
      </div>
    </div>
    <b-row class="mt-2">
      <b-col :cols="dataEmployeeTable != null ? '6' : '12'" v-if="asChief">
        <!--        FOR CHIEF-->
        <table-overtime-pending
          :key="tablePending"
          ref="overtimePendingChief"
          :dataEmployee="dataEmployeeTable"
          :valueFilterDay="filters.filterDay"
          :valueFilterWeekStart="filters.filterWeekStart"
          :valueFilterWeekEnd="filters.filterWeekEnd"
          :valueFilterMonth="filters.filterMonth"
          :valueFilterYear="filters.filterYear"
          @addValidate="addValidate"
          @refreshData="tableApproved++"
        ></table-overtime-pending>
      </b-col>
      <b-col :cols="dataEmployeeTable != null ? '6' : '12'" v-else>
        <!--        FOR RRHH AND MANAGEMENT-->
        <table-overtime-pending-rrhh-management
          ref="overtimePendingRRhhManagement"
          :key="tableApprovedRRhhManagement"
          :dataEmployee="dataEmployeeTable"
          :valueFilterDay="filters.filterDay"
          :valueFilterWeekStart="filters.filterWeekStart"
          :valueFilterWeekEnd="filters.filterWeekEnd"
          :valueFilterMonth="filters.filterMonth"
          :valueFilterYear="filters.filterYear"
          @refreshTable="tableApproved++"
        />
      </b-col>
      <b-col cols="6">
        <!--    status 1 for approved justifications / overtime-->
        <!--        For chief, rrhh and management-->
        <table-overtime-approved
          v-if="dataEmployeeTable != null"
          :key="tableApproved"
          :dataEmployee="dataEmployeeTable"
          :valueFilterDay="filters.filterDay"
          :valueFilterWeekStart="filters.filterWeekStart"
          :valueFilterWeekEnd="filters.filterWeekEnd"
          :valueFilterMonth="filters.filterMonth"
          :valueFilterYear="filters.filterYear"
          :status="1"
          :user="'chief'"
        ></table-overtime-approved>
      </b-col>
    </b-row>
    <div style="width: 100%">
      <div class="d-flex justify-content-center">
        <b-button
          variant="success"
          @click="validateOvertime()"
          class="float-right"
          style="margin-top: 5px"
        >
          <tabler-icon icon="ClockIcon" size="18" /> Confirm Hours
          <tabler-icon icon="ChevronsRightIcon" size="18" />
        </b-button>
        <!-- <b-button
          v-if="limitDate"
          variant="success"
          @click="validateOvertime()"
          class="float-right"
          style="margin-top: 5px"
        >
          <tabler-icon icon="ClockIcon" size="18" /> Confirm Hours
          <tabler-icon icon="ChevronsRightIcon" size="18" />
        </b-button>
        <span v-else class="p-2 mt-2 mb-2 rounded list-group-item-danger w-100 text-center" style="font-size:18px;">Out of date deadline for data update</span> -->
      </div>
    </div>
    <modal-add-hours
      v-if="showAddHours"
      :employee_id="
        dataEmployeeTable != null ? dataEmployeeTable.user_id : null
      "
      :valueFilterMonth="this.valueFilterMonth"
      :valueFilterYear="this.valueFilterYear"
      @hidden="closeModalAddHours"
    ></modal-add-hours>
    <modal-rejected-justifications
      v-if="openModalRejectedJustifications"
      :filters-date="filters"
      :employee-data="dataEmployeeTable"
      @close="closeRejectedJustificationsModal"
    />
  </b-modal>
</template>

<script>
import TableOvertimeApproved from "@/views/assistance/views/dashboard/components/Overtime/TableOvertimeApproved.vue";
import TableOvertimePending from "@/views/assistance/views/dashboard/components/Overtime/TableOvertimePending.vue";
import ModalAddHours from "@/views/assistance/views/dashboard/components/Overtime/ModalAddHours.vue";
import TableOvertimePendingRrhhManagement from "@/views/assistance/views/dashboard/components/Overtime/TableOvertimePendingRrhhManagement.vue";
import DashboardServices from "@/views/assistance/views/dashboard/services/dashboard.services.js";
import ModalRejectedJustifications from "@/views/assistance/views/dashboard/components/Overtime/modals/ModalRejectedJustifications.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    TableOvertimeApproved,
    TableOvertimePending,
    ModalAddHours,
    TableOvertimePendingRrhhManagement,
    ModalRejectedJustifications,
  },
  props: {
    dataEmployee: null,
    valueFilterDay: null,
    valueFilterWeekStart: null,
    valueFilterWeekEnd: null,
    valueFilterMonth: null,
    valueFilterYear: null,
  },

  data() {
    return {
      dataEmployeeTable: null,
      filters: {
        filterDay: null,
        filterWeekStart: null,
        filterWeekEnd: null,
        filterMonth: null,
        filterYear: null,
      },
      show: false,
      dataValidated: [],
      tablePending: 0,
      tableApproved: 0,
      tableApprovedRRhhManagement: 0,
      showAddHours: false,
      openModalRejectedJustifications: false,
    };
  },
  created() {
    this.show = true;
    this.dataEmployeeTable = this.dataEmployee;
    this.filters.filterDay = this.valueFilterDay;
    this.filters.filterWeekStart = this.valueFilterWeekStart;
    this.filters.filterWeekEnd = this.valueFilterWeekEnd;
    this.filters.filterMonth = this.valueFilterMonth;
    this.filters.filterYear = this.valueFilterYear;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isRRHH() {
      return this.moduleId === 17;
    },
    isManagement() {
      return this.moduleId === 16;
    },
    isNotRRHHorManagement() {
      return !this.isRRHH && !this.isManagement;
    },
    asChief() {
      // If is rrhh or management and tabRole is chief will access as chief role, in the other modules that are not rrhh or management will access as chief too
      return (
        ((this.isRRHH || this.isManagement) &&
          this.$route.meta.tabRole == "chief") ||
        this.isNotRRHHorManagement
      );
    },
    // limitDate() {
    // const currentMonth = new Date().getMonth() + 1;
    // const currentYear = new Date().getFullYear();

    // const filterMonth = parseInt(this.filters.filterMonth);
    // const filterYear = parseInt(this.filters.filterYear);

    // if (filterYear < currentYear) {
    //   return false;
    // }
    // if (filterMonth == currentMonth) {
    //   return true;
    // } else if (filterMonth == currentMonth - 1) {
    //   const currentDay = new Date().getDate();
    //   if (currentDay == 1) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return false;
    // }
    // },
  },
  methods: {
    cleanDataValidated() {
      this.dataValidated = [];
    },
    hidden() {
      this.$emit("closing");
    },
    addValidate(
      date,
      id_justification,
      state,
      hours,
      motive,
      action,
      employee_id
    ) {
      if (this.dataValidated.length > 0) {
        const exist = this.dataValidated.find(
          (item) => item.date === date && item.employee_id === employee_id
        );
        if (exist) {
          this.dataValidated = this.dataValidated.filter(
            (item) => item.date !== date || item.employee_id !== employee_id
          );
        }
      }
      if (action === true) {
        this.dataValidated.push({
          date: date,
          id_justification: id_justification,
          state: state,
          hours: hours,
          motive: motive,
          employee_id: employee_id,
        });
      } else {
        this.dataValidated = this.dataValidated.filter(
          (item) => item.date !== date || item.employee_id !== employee_id
        );
      }
    },
    async validateOvertime() {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;

      try {
        this.addPreloader();
        if (this.asChief) {
          await this.$refs.overtimePendingChief.confirmHoursChief();
          // const params = {
          //   data: this.dataValidated,
          //   employee_id: null,
          //   user_id: this.currentUser.user_id,
          // };
          // const response = await DashboardServices.insertApprovedOvertime(
          //   params
          // );
          // if (response.status === 200) {
          //   this.showSuccessSwal("Success", "Overtime Approved Successfully");
          //   this.dataValidated = [];
          //   this.tablePending++;
          //   this.tableApprovedRRhhManagement++;
          //   this.tableApproved++;
          // }
          // this.removePreloader();
        } else {
          await this.$refs.overtimePendingRRhhManagement.confirmHoursRRhhManagement();
        }
        this.$emit("refresh");
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
        console.error(error);
      }
    },
    async addOvertime() {
      this.showAddHours = true;
    },
    closeModalAddHours() {
      this.showAddHours = false;
      this.tableApproved++;
    },
    openRejectedJustificationsModal() {
      this.openModalRejectedJustifications = true;
    },
    closeRejectedJustificationsModal() {
      this.openModalRejectedJustifications = false;
    },
  },
};
</script>
