<template>
  <b-modal
    v-model="onModal"
    :size="'xmd'"
    title="Employees Lateness"
    hide-footer
    no-close-on-backdrop
    scrollable
    @hidden="close"
  >
    <div>
      <filter-slot
        :filter="[]"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :no-visible-principal-filter="true"
        @reload="$refs['refLatenessList'].refresh()"
      >
        <b-table
          slot="table"
          ref="refLatenessList"
          no-provider-filtering
          :items="lateProvider"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(number)="data">
            <span>{{ data.item.number }}</span>
          </template>
          <template #cell(employee_name)="data">
            <span>{{ formatTitleCase(data.item.employee_name) }}</span>
          </template>
          <template #cell(mark_time)="data">
            <span>{{ data.item.mark_time | myGlobalDay }}</span>
          </template>
          <template #cell(work_start_time)="data">
            <span>{{ data.item.work_start_time }}</span>
          </template>
        </b-table>
      </filter-slot>
    </div>
  </b-modal>
</template>

<script>
import DashboardServices from '@/views/assistance/views/dashboard/services/dashboard.services.js';

export default {
  props: {
    valueFilterDay: null,
    valueFilterWeekStart: null,
    valueFilterWeekEnd: null,
    valueFilterMonth: null,
    valueFilterYear: null,
    valueFilterModule: null,
    valueFilterEmployee: null,
  },

  data() {
    return {
      onModal: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      isBusy: false,
      totalRows: 0,
      startPage: null,
      toPage: null,
      sortBy: '',
      sortDesc: true,
      fields: [
        {
          key: 'number',
          label: 'ID Employee',
          visible: true,
        },
        {
          key: 'employee_name',
          label: 'Employee Name',
          visible: true,
        },
        {
          key: 'mark_time',
          label: 'Mark Time',
          visible: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      listEmployeesLate: [],
      filterDay: false,
      filterWeek: true,
      filterMonth: false,
      filterYear: false,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    this.onModal = true;
  },
  methods: {
    formatTitleCase(text) {
      return text
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    close() {
      this.$emit('closeModal');
    },
    async lateProvider(ctx) {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sortOrder: ctx.sortDesc ? 'ASC' : 'DESC',
          orderBy: 10,
          module_id: this.valueFilterModule,
          user_id: this.valueFilterEmployee ? this.valueFilterEmployee : null,
          filterDay: this.valueFilterDay,
          filterWeekStart: this.valueFilterWeekStart,
          filterWeekEnd: this.valueFilterWeekEnd,
          filterMonth: this.valueFilterMonth,
          filterYear: this.valueFilterYear,
        };
        const data = await DashboardServices.getLatenessOfEmployees(params);

        if (data.data.status === 200) {
          this.listEmployeesLate = data.data.data.data;
          this.totalRows = data.data.data.total;
          this.startPage = data.data.data.from || 0;
          this.toPage = data.data.data.to || 0;
          this.paginate.currentPage = data.data.data.current_page;
          this.paginate.perPage = data.data.data.per_page;
          return this.listEmployeesLate || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },
  },
};
</script>
