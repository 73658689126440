<template>
  <div>
    <!-- -->
    <b-modal
      v-model="oncontrol"
      :header-bg-variant="isDarkSkin ? 'dark':'info'"
      :header-text-variant="isDarkSkin ? 'white':'black'"
      :size="size"
      centered
      @hidden="closeModal"
    >
      <template #modal-title>
        <div>
          <feather-icon
            icon="UserIcon"
            class="text-white"
            size="18"
          />
          <strong>Marks of {{ nameEmployee }}</strong>
        </div>
      </template>
      <b-card
        no-body
        class="shadow-none px-2 pt-1"
      >
        <b-row>
          <b-col cols="12">
            <b-skeleton-img
              v-if="loading"
              style="max-heigth: 300px;"
            />
            <vue-apex-charts
              v-else
              :key="key"
              type="line"
              height="350"
              :options="chartOptions"
              :series="series"
            />
          </b-col>
        </b-row>
      </b-card>
      <template #modal-footer>
        <div />
      </template>
    </b-modal>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
import DashboardServices from '@/views/assistance/views/dashboard/services/dashboard.services';

export default {
  components: {
    VueApexCharts,
  },
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    employeeId: {
      type: Number,
      default: () => 0,
    },
    nameEmployee: {
      type: String,
      default: () => null,
      require: true,
    },
  },
  data() {
    return {
      loading: true,
      size: 'lg',
      key: 0,
      oncontrol: false,
      series: [
      ],
      chartOptions: {
        chart: {
          height: 500,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0,
          },
          toolbar: {
            show: false,
          },
          events: {
            mouseMove: (event, chartContext) => {
              const tooltip = chartContext.el.querySelector('.apexcharts-tooltip');
              const tooltipTitle = chartContext.el.querySelector(
                '.apexcharts-tooltip-title',
              );
              const color = this.isDarkSkin ? '#F7F7F7' : '#000000';
              const backgroundColor = this.isDarkSkin ? '#3A3A3A' : '#ffffff';
              if (tooltip) {
                tooltip.style.backgroundColor = backgroundColor;
                tooltip.style.color = color;
                tooltip.style.border = '1px solid #000000';
              }
              if (tooltipTitle) {
                tooltipTitle.style.backgroundColor = backgroundColor;
                tooltipTitle.style.color = color;
                tooltipTitle.style.borderBottom = '1px solid #000000';
              }
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: [0, 0, 0, 0],
          curve: 'straight',
          dashArray: [0, 0, 0, 0],
        },
        title: {
          text: '',
          align: 'center',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
            opacity: 1,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
          title: {
            text: 'DATES',
          },
          labels: {
            show: true,
            style: {
              colors: '#6e6b7b',
            },
          },
        },
        yaxis: {
          title: {
            text: 'Hours',
          },
          min: 0,
          max: 24,
          labels: {
            show: true,
            style: {
              colors: '#6e6b7b',
            },
          },
        },
        legend: {
          tooltipHoverFormatter(val, opts) {
            return `${val} - ${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}`;
          },
        },
        tooltip: {
          y: [
            {
              title: {
                formatter(val) {
                  return `${val} (Time)`;
                },
              },
            },
            {
              title: {
                formatter(val) {
                  return `${val} (Time)`;
                },
              },
            },
            {
              title: {
                formatter(val) {
                  return `${val} (Time)`;
                },
              },
            },
            {
              title: {
                formatter(val) {
                  return `${val} (Time)`;
                },
              },
            },
          ],
        },

      },
    };
  },
  watch: {
    skin(oldVal, newVal) {
      if (newVal === 'light') {
        this.chartOptions.grid.row.colors = ['transparent', 'transparent'];
      } else {
        this.chartOptions.grid.row.colors = ['transparent', 'transparent'];
      }
      this.keyChart += 1;
    },
  },
  async mounted() {
    if (this.isDarkSkin) {
      this.chartOptions.xaxis.labels.style.colors = 'white';
      this.chartOptions.yaxis.labels.style.colors = 'white';
    } else {
      this.chartOptions.xaxis.labels.style.colors = 'black';
      this.chartOptions.yaxis.labels.style.colors = 'black';
    }
    this.size = !this.filters.day && !this.filters.weekStart && !this.filters.weekEnd ? 'xl' : 'lg';
    this.oncontrol = true;
    await this.getDetailsMarks();
  },
  methods: {
    async getDetailsMarks() {
      try {
        const params = {
          user_id: this.employeeId,
          filterDay: this.filters.day,
          filterWeekStart: this.filters.weekStart,
          filterWeekEnd: this.filters.weekEnd,
          filterMonth:
            !this.filters.day
            && !this.filters.weekStart
            && !this.filters.weekEnd
              ? parseInt(this.filters.month, 10)
              : null,
          filterYear:
            !this.filters.day
            && !this.filters.weekEnd
            && !this.filters.weekStart
              ? parseInt(this.filters.year, 10)
              : null,
        };
        const { data } = await DashboardServices.getDetailsAttendance(params);
        this.optionsFullMarks(data.data);
      } catch (error) {
        console.log('error to get details-attendance to report marks', error);
      }
    },
    optionsFullMarks(data) {
      const firstMarks = { name: 'First Mark', data: data.map(item => item.first_mark || 0) };
      const secondMarks = { name: 'Second Mark', data: data.map(item => item.second_mark || 0) };
      const thirdMarks = { name: 'Third Mark', data: data.map(item => item.third_mark || 0) };
      const fourMarks = { name: 'Four Mark', data: data.map(item => item.fourth_mark || 0) };

      const optionsMarks = [
        firstMarks,
        secondMarks,
        thirdMarks,
        fourMarks,
      ];
      this.chartOptions.xaxis.categories = data.map(item => moment(item.date_mark, 'YYYY-MM-DD').format('MM-DD-YYYY'));
      this.series = optionsMarks;
      this.loading = false;
      this.key += 1;
    },
    closeModal() {
      this.oncontrol = false;
      this.$emit('closeModal');
    },
  },
};
</script>
