<template>
  <section class="p-1">
    <b-row>
      <b-col cols="12">
        <!--Options-->
        <b-row class="mt-2 d-flex justify-content-beetween">
          <!-- Filter Graphic Report-->
          <b-col cols="12" xl="6" lg="6" md="6" class="mt-lg-2">
            <div id="filters">
              <customized-radio-group
                id="type-filter"
                :options="firstFilter.options"
                class="filter-item"
                @changeOption="
                  (data) => {
                    firstFilter.value = data;
                  }
                "
              />
              <div id="delimiter-filters" class="filter-item" />
              <customized-radio-group
                id="period-filter"
                class="filter-item"
                :options="secondFilter.options"
                :selected-option="'week'"
                @changeOption="(data) => handleFilterChange(data)"
              />
            </div>
          </b-col>
          <!-- Filter Month Week Day-->
          <b-col
            cols="12"
            xl="6"
            lg="6"
            class="d-flex justify-content-xl-end justify-content-md-start align-items-center flex-wrap"
            :class="firstFilter.value == 'report' ? 'pr-xl-0' : 'pr-xl-0'"
          >
            <b-form-group
              v-if="firstFilter.value == 'report' && isManagementAmgAttendance"
            >
              <button
                type="button"
                class="btn btn-outline-info"
                @click="showAddMarksTracking"
              >
                <feather-icon icon="ArrowUpCircleIcon" size="16" class="" />
                Tracking Marks
              </button>
            </b-form-group>
            <b-form-group
              v-if="
                firstFilter.value == 'report' &&
                filterMonth &&
                isManagementAmgAttendance &&
                filterModule.value != null
              "
              label="Employee:"
              class="mr-1"
              style="width: 200px"
            >
              <vue-autosuggest
                ref="autocomplete"
                :suggestions="filteredOptions"
                :get-suggestion-value="getSuggestionValue"
                :input-props="
                  filterModule.value == null
                    ? {
                        id: 'autosuggest__input',
                        class: 'form-control',
                        disabled: true,
                      }
                    : {
                        id: 'autosuggest__input',
                        class: 'form-control',
                        disabled: false,
                        placeholder: 'Search an employee',
                      }
                "
                @input="onInputChange"
                @selected="selectHandler"
              >
                <template slot-scope="{ suggestion }">
                  <span class="my-suggestion-item">{{
                    suggestion.item.value
                  }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
            <div class="d-flex mr-2 align-items-center">
              <b-dropdown
                v-if="
                  firstFilter.value == 'report' && isManagementAmgAttendance
                "
                id="generate-report"
                text="Generate Report"
                variant="success"
                split
                split-variant="outline-success"
              >
                <template #button-content>
                  <feather-icon
                    v-if="!loadingExcel"
                    icon="DownloadIcon"
                    class="mr-1"
                  />
                  <feather-icon v-else icon="LoaderIcon" class="mr-1" />
                  <span class="align-middle">Download Excel</span>
                </template>
                <b-dropdown-item @click="donwloadExcel(0)">
                  Detailed Report
                </b-dropdown-item>
                <b-dropdown-item @click="donwloadExcel(1)">
                  General Report
                </b-dropdown-item>
              </b-dropdown>

              <div
                class="d-flex justify-content-start align-items-center"
                :class="firstFilter.value == 'report' ? 'pr-1 mr-0' : ''"
              >
                <b-button
                  v-if="asChief"
                  id="add-hours"
                  class="mt-n1 float-right mr-1 customized-button"
                  @click="addOvertime()"
                >
                  <span>Add hours</span>
                </b-button>
                <b-button-group v-if="asChief" class="mt-n1 float-right mr-1">
                  <b-button
                    id="add-marks"
                    class="customized-button left-item"
                    @click="addMarks()"
                  >
                    <span>Add marks</span>
                  </b-button>
                  <b-button
                    id="marks-tracking"
                    class="customized-button right-item"
                    @click="showAddMarksTracking"
                  >
                    <feather-icon icon="MenuIcon" size="20" />
                  </b-button>
                </b-button-group>
                <b-button
                  v-if="asChief"
                  id="pending-approval"
                  class="mt-n1 ml-1 float-right mr-1 customized-button"
                  @click="showAllHoursToApproved()"
                >
                  <span>Pending Approval</span>
                </b-button>
                <b-button
                  v-else
                  variant="warning"
                  class="ml-xl-2 mt-n1 mr-1"
                  @click="showAllHoursToApproved()"
                >
                  <tabler-icon icon="ClockIcon" size="15" /> Pending Approval
                </b-button>
                <password-app-handler
                  v-if="asParagonChief"
                  id="password-app-handler"
                  class="mt-n1 float-right"
                  :class="firstFilter.value == 'report' ? 'pr-3 ml-0' : ''"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <!--End Options-->
        <hr style="border: 1px solid #0090e7" />
        <b-row class="d-flex justify-content-between">
          <!-- Filter Select Day-Week-Month -->

          <b-col cols="auto" style="display: none">
            <div
              :style="firstFilter.value == 'report' ? 'margin-right: 80px' : ''"
            >
              <b-row class="pl-1 pr-1">
                <b-card
                  class="rounded shadow-sm shadow-none border-light"
                  :class="isDarkSkin ? 'border-secondary' : ''"
                >
                  <b-card-text>
                    <span
                      class="d-flex justify-content-center align-items-center text-center"
                      style="line-height: 11px !important"
                    >
                      Working Hours
                      <feather-icon
                        id="working_hours"
                        icon="InfoIcon"
                        class="cursor-pointer"
                        style="margin-left: 5px"
                      />
                      <b-tooltip
                        target="working_hours"
                        triggers="hover"
                        placement="right"
                      >
                        Working hours to be completed by the employee (day,
                        week, month)
                      </b-tooltip></span
                    >

                    <label
                      for=""
                      class="w-100 text-info d-flex justify-content-center mt-1 font-weight-bolder"
                      style="font-size: 22px"
                      >{{ hoursWorkedByFilter }}</label
                    >
                  </b-card-text>
                </b-card>
                <b-card
                  class="ml-1 ml-sm-2 rounded shadow-sm shadow-none border-light"
                  :class="isDarkSkin ? 'border-secondary' : ''"
                >
                  <b-card-text
                    class="d-flex align-items-center justify-content-center"
                  >
                    <div>
                      <span
                        class="d-flex justify-content-center align-items-center text-center"
                        style="line-height: 10px !important"
                        >Holidays Hours
                        <feather-icon
                          id="holiday_hours"
                          icon="InfoIcon"
                          class="cursor-pointer"
                          style="margin-left: 5px"
                        />
                        <b-tooltip
                          target="holiday_hours"
                          triggers="hover"
                          placement="right"
                        >
                          Employee's holiday hours (day, week, month)
                        </b-tooltip>
                      </span>
                      <label
                        for=""
                        class="w-100 d-flex justify-content-center mt-1 font-weight-bolder text-success"
                        style="font-size: 22px"
                        >{{ hour_holidays }}</label
                      >
                    </div>
                  </b-card-text>
                </b-card>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="firstFilter.value == 'report'" class="mt-2">
          <b-col cols="12">
            <div>
              <filter-slot
                :filter="[]"
                :filter-principal="filterPrincipal"
                :total-rows="totalRows"
                :paginate="paginate"
                :start-page="startPage"
                :to-page="toPage"
                :send-multiple-sms="false"
                :change-columns-by-client="true"
                @reload="refreshTable"
              >
                <template #buttons>
                  <div
                    id="delimiter-filters-report"
                    class="filter-item"
                    style="margin: 0 1em"
                  />
                  <div
                    class="time-filter"
                    style="margin-top: 0px !important; max-width: 600px"
                  >
                    <!-- Filter select Module -->
                    <div
                      v-if="asRRHHOrManagement"
                      class="d-flex justify-content-center align-items-center flex-wrap"
                      style="margin: 0 1rem"
                    >
                      <strong
                        for
                        style="font-size: 18px; white-space: nowrap"
                        class="mr-1 mb-1"
                        >Select Module</strong
                      >

                      <b-form-select
                        v-model="filterModule.value"
                        :options="filterModule.options"
                        value-field="id"
                        text-field="name"
                        size="sm"
                        style="max-width: 200px"
                        @change="handleFilterChange"
                      />
                    </div>

                    <!-- Filter Change Week -->
                    <div
                      v-if="secondFilter.value == 'week'"
                      class="filter-week-report"
                      :style="
                        firstFilter.vale == 'report'
                          ? 'margin-top:0!important'
                          : ''
                      "
                      :class="!isDarkSkin ? 'bg-white' : 'bg-dark'"
                    >
                      <b-button
                        variant="primary"
                        class="btn-icon arrow-button"
                        @click="changeWeek(-1)"
                      >
                        <feather-icon icon="ChevronLeftIcon" />
                      </b-button>
                      <span
                        style="font-size: 15px"
                        class="filter-week-text"
                        :style="!isDarkSkin ? 'border:1px solid #BDBDBD;' : ''"
                      >
                        {{ paramsWeek.from | myGlobal }} -
                        {{ paramsWeek.to | myGlobal }}
                      </span>

                      <b-button
                        variant="primary"
                        class="btn-icon arrow-button btn-primary"
                        :disabled="disabledBtnEmployees"
                        @click="changeWeek(1)"
                      >
                        <feather-icon icon="ChevronRightIcon" />
                      </b-button>
                    </div>

                    <!-- Filter Change Day -->
                    <b-form-group
                      v-if="filterDay"
                      label="Start Day"
                      label-for="start_day"
                      class="day-filter-report"
                      style="width: 16em; margin-top: 0 !important"
                    >
                      <b-form-datepicker
                        id="start_day"
                        v-model="paramsWeek.from"
                        class="custom-date-picker"
                        :style="
                          !isDarkSkin
                            ? 'border:1px solid #BDBDBD;'
                            : 'border:0px;background: #292C33;'
                        "
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        @input="onDateSelected"
                      >
                        <template #button-content>
                          <svg
                            width="17"
                            height="19"
                            viewBox="0 0 17 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="calendar-icon"
                          >
                            <path
                              d="M14.3333 3H2.66667C1.74619 3 1 3.74619 1 4.66667V16.3333C1 17.2538 1.74619 18 2.66667 18H14.3333C15.2538 18 16 17.2538 16 16.3333V4.66667C16 3.74619 15.2538 3 14.3333 3Z"
                              stroke="#656565"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 1V4"
                              stroke="#656565"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5 1V4"
                              stroke="#656565"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1 8H16"
                              stroke="#656565"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </template>
                      </b-form-datepicker>
                    </b-form-group>
                    <b-form-group
                      v-if="filterDay"
                      label="End Date"
                      label-for="end_day"
                      class="day-filter"
                      style="width: 16em; margin-top: 0 !important"
                    >
                      <b-form-datepicker
                        id="end_day"
                        v-model="paramsWeek.to"
                        class="custom-date-picker"
                        :style="
                          !isDarkSkin
                            ? 'border:1px solid #BDBDBD;'
                            : 'border:0px;background: #292C33;'
                        "
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        @input="onDateSelected"
                      >
                        <template #button-content>
                          <svg
                            class="calendar-icon"
                            width="17"
                            height="19"
                            viewBox="0 0 17 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.3333 3H2.66667C1.74619 3 1 3.74619 1 4.66667V16.3333C1 17.2538 1.74619 18 2.66667 18H14.3333C15.2538 18 16 17.2538 16 16.3333V4.66667C16 3.74619 15.2538 3 14.3333 3Z"
                              stroke="#656565"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 1V4"
                              stroke="#656565"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5 1V4"
                              stroke="#656565"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1 8H16"
                              stroke="#656565"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </template>
                      </b-form-datepicker>
                    </b-form-group>
                    <!-- Filter Change Month -->
                    <b-form-group
                      v-if="filterMonth"
                      label="Month"
                      label-for="month"
                      class="select-container-report"
                      style="margin-top: 1em !important"
                    >
                      <b-form-select
                        v-model="valueFilterMonth"
                        style="min-width: 120px"
                        :options="months"
                        class="customized-select"
                        :style="
                          !isDarkSkin
                            ? 'border:1px solid #BDBDBD;'
                            : 'border:0px;background: #292C33;'
                        "
                        @input="onDateSelected"
                      />
                    </b-form-group>
                    <!-- Filter Change Year -->
                    <b-form-group
                      v-if="filterMonth"
                      label="Year"
                      label-for="year"
                      class="select-container-report"
                      style="margin-top: 1em !important"
                    >
                      <b-form-select
                        v-model="valueFilterYear"
                        style="min-width: 120px"
                        :options="years"
                        class="customized-select"
                        :style="
                          !isDarkSkin
                            ? 'border:1px solid #BDBDBD;'
                            : 'border:0px;background: #292C33;'
                        "
                        @input="onDateSelected"
                      />
                    </b-form-group>
                  </div>
                </template>
                <b-table
                  slot="table"
                  ref="refEmployeesList"
                  no-provider-filtering
                  :items="employeesReportProvider"
                  :fields="visibleFields"
                  primary-key="id"
                  table-class="text-nowrap"
                  show-empty
                  sticky-header="40vh"
                  :busy.sync="isBusy"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :current-page="paginate.currentPage"
                  :per-page="paginate.perPage"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle mr-1" />
                      <strong>Loading ...</strong>
                    </div>
                  </template>
                  <template #head(num_marks)>
                    <span># Marks</span>
                    <feather-icon
                      id="num_marks"
                      icon="InfoIcon"
                      class="ml-1 cursor-pointer"
                    />
                    <b-tooltip
                      target="num_marks"
                      triggers="hover"
                      placement="right"
                    >
                      Number of times the employee has marked his/her attendance
                      in the app
                    </b-tooltip>
                  </template>
                  <template #head(working_hours_valid)>
                    <span>Worked Hours</span>
                    <feather-icon
                      id="worked_hours"
                      icon="InfoIcon"
                      class="ml-1 cursor-pointer"
                    />
                    <b-tooltip
                      triggers="hover"
                      target="worked_hours"
                      placement="right"
                    >
                      <b
                        >Hours the employee has worked according to maximum
                        amount of hours per day</b
                      >
                      <ul class="mt-1 list-unstyled">
                        <li><b>Monday - Friday :</b> 8 hours</li>
                        <li><b>Saturday - Sunday :</b> 6 hours</li>
                      </ul>
                    </b-tooltip>
                  </template>
                  <template #head(working_hours_rounded)>
                    <span>Rounded Hours</span>
                    <feather-icon
                      id="working_hours_rounded"
                      icon="InfoIcon"
                      class="ml-1 cursor-pointer"
                    />
                    <b-tooltip
                      triggers="hover"
                      target="working_hours_rounded"
                      placement="right"
                    >
                      Hours the employee has worked according to the marks
                    </b-tooltip>
                  </template>
                  <template #head(total)>
                    <span>Total</span>
                    <feather-icon
                      id="total"
                      icon="InfoIcon"
                      class="ml-1 cursor-pointer"
                    />
                    <b-tooltip
                      triggers="hover"
                      target="total"
                      placement="right"
                    >
                      Hours valid
                    </b-tooltip>
                  </template>
                  <template #head(missing_hours)>
                    <span>Missing Hours</span>
                    <feather-icon
                      id="missing_hours"
                      icon="InfoIcon"
                      class="ml-1 cursor-pointer"
                    />
                    <b-tooltip
                      triggers="hover"
                      target="missing_hours"
                      placement="right"
                    >
                      <b
                        >Hours the employee fell short of meeting the minimum
                        required hours</b
                      >
                      <ul class="mt-1 list-unstyled">
                        <li v-if="secondFilter.value == 'day'">
                          monday - friday : Min 8 hours<br />
                          saturday - sunday : Min 6 hours<br />
                        </li>
                        <li v-else-if="secondFilter.value == 'week'">
                          Min 46 hours per week
                        </li>
                        <li v-else>Min of hours according to the month</li>
                      </ul>
                    </b-tooltip>
                  </template>
                  <template #head(overtime)>
                    <span>Hours to approve</span>
                    <feather-icon
                      id="overtime"
                      icon="InfoIcon"
                      class="ml-1 cursor-pointer"
                    />
                    <b-tooltip
                      triggers="hover"
                      target="overtime"
                      placement="right"
                    >
                      Hours to justificate
                    </b-tooltip>
                  </template>
                  <template #head(overtime_pending)>
                    <span>In process</span>
                    <feather-icon
                      id="overtime_pending"
                      icon="InfoIcon"
                      class="ml-1 cursor-pointer"
                    />
                    <b-tooltip
                      triggers="hover"
                      target="overtime_pending"
                      placement="right"
                    >
                      Pending from RRHH or management approval (according to the
                      process)
                    </b-tooltip>
                  </template>
                  <template #head(overtime_approved)>
                    <span>Approved Hours</span>
                    <feather-icon
                      id="overtime_approved"
                      icon="InfoIcon"
                      class="ml-1 cursor-pointer"
                    />
                    <b-tooltip
                      triggers="hover"
                      target="overtime_approved"
                      placement="right"
                    >
                      <b
                        >Approved hours by RRHH or Management (according to the
                        process)</b
                      >
                      <ul class="mt-1 list-unstyled">
                        <li>Faults -> Chief -> Management</li>
                        <li>Permission -> Chief -> Management</li>
                        <li>Late -> Chief -> Management</li>
                        <li>Remote and other -> Chief -> Management</li>
                        <li>Medical rest -> Chief -> Human Talent</li>
                        <li>Vacations / Birthday -> Human Talent</li>
                      </ul>
                    </b-tooltip>
                  </template>
                  <template #cell(employee)="data">
                    <span>
                      <span class="mr-1">
                        <b-button
                          variant="info"
                          style="padding: 5px"
                          @click="toggleDetails(data.item)"
                        >
                          <feather-icon icon="SearchIcon" size="15" />
                        </b-button>
                      </span>
                      <span>{{ data.item.employee }}</span>
                    </span>
                  </template>
                  <template #cell(missing_hours)="data">
                    <div class="d-flex justify-content-center">
                      <span
                        class="text-white"
                        :class="'border bg-danger rounded px-1 text-center'"
                        style="padding: 0.5em 0"
                      >
                        {{
                          data.item.missing_hours_registered && filterMonth
                            ? data.item.missing_hours_registered
                            : data.item.missing_hours
                        }}
                      </span>
                    </div>
                  </template>

                  <template #cell(overtime)="data">
                    <div class="d-flex justify-content-center">
                      <span
                        style="cursor: pointer; padding: 0.5em 0"
                        :class="
                          data.item.overtime > 0
                            ? 'border bg-primary rounded px-1 text-center text-white'
                            : 'px-1 text-center'
                        "
                        @click="openModalOvertime(data.item)"
                        >{{ data.item.overtime }}</span
                      >
                    </div>
                  </template>
                  <template #cell(overtime_pending)="data">
                    <div class="d-flex justify-content-center">
                      <span
                        class="text-white"
                        style="cursor: pointer"
                        :class="
                          data.item.overtime_pending > 0
                            ? 'border bg-warning rounded px-1 text-center'
                            : 'text-secondary px-1 text-center'
                        "
                        @click="openModalOvertime(data.item)"
                        >{{ data.item.overtime_pending }}</span
                      >
                    </div>
                  </template>
                  <template #cell(overtime_approved)="data">
                    <div class="d-flex justify-content-center">
                      <span
                        class="text-white"
                        style="cursor: pointer"
                        :class="
                          data.item.overtime_approved > 0
                            ? 'border bg-success rounded px-1 text-center'
                            : 'text-secondary px-1 text-center'
                        "
                        @click="openModalOvertime(data.item)"
                        >{{ data.item.overtime_approved }}</span
                      >
                    </div>
                  </template>
                  <template #cell(hours_holyday)="data">
                    {{ `${data.item.hours_holyday} h` }}
                  </template>
                  <template #cell(total)="data">
                    <div class="d-flex justify-content-center">
                      <span
                        class="text-white"
                        :class="'border bg-info rounded px-1 text-center'"
                        style="padding: 0.5em 0"
                      >
                        {{
                          data.item.total_registered && filterMonth
                            ? data.item.total_registered
                            : data.item.total
                        }}
                      </span>
                      <span
                        v-if="
                          data.item.total_registered >= 0 &&
                          filterMonth &&
                          data.item.counter_total_hours != 0 &&
                          isManagementAmgAttendance
                        "
                        class="text-center"
                        style="cursor: pointer; margin-left: 5px"
                        @click="
                          changeTotalHours(
                            data.item.total_registered,
                            data.item.user_id
                          )
                        "
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="16"
                          class="text-success"
                        />
                      </span>
                    </div>
                  </template>
                  <!-- <template #row-details="data">
                    <b-card
                      :style="
                        isDarkSkin ? '' : 'background-color: #F7F9F9 !important'
                      "
                    >
                      <div
                        v-if="secondFilter.value == 'month'"
                        class="mb-1"
                      >
                        <b-button
                          :variant="
                            typeViewDetail == 'calendar'
                              ? 'primary'
                              : 'outline-primary'
                          "
                          @click="typeView('calendar')"
                        >Calendar</b-button>
                        <b-button
                          class="ml-1"
                          :variant="
                            typeViewDetail == 'grid'
                              ? 'primary'
                              : 'outline-primary'
                          "
                          @click="typeView('grid')"
                        >Grid</b-button>
                      </div>
                      <grid-months-marks
                        v-if="
                          secondFilter.value == 'month' &&
                            typeViewDetail == 'calendar'
                        "
                        :key="keyGrid"
                        :filters="{
                          userId: user_id_month,
                          month: valueFilterMonth,
                          year: valueFilterYear,
                        }"
                      />
                      <grid-details-marks
                        v-else
                        :key="keyGrid"
                        :filters="{
                          userId: data.item.user_id,
                          day: valueFilterDay,
                          weekStart: paramsWeek.from,
                          weekEnd: paramsWeek.to,
                          month: valueFilterMonth,
                          year: valueFilterYear,
                        }"
                      />
                    </b-card>
                  </template> -->
                </b-table>
              </filter-slot>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-row v-if="firstFilter.value !== 'report'">
        <b-col id="marks-graphic" cols="12">
          <b-row class="marks-container">
            <b-col cols="12" lg="9">
              <div
                v-if="firstFilter.value !== 'report'"
                class="time-filters-container"
              >
                <div class="marks-title">Marks by employees</div>
                <div class="time-filter" style="margin-top: 5em">
                  <!-- Filter select Module -->
                  <div
                    v-if="asRRHHOrManagement"
                    class="rounded py-1 px-2 shadow w-auto d-flex justify-content-center align-items-center flex-wrap"
                    style="margin: 0 1rem"
                    :class="!isDarkSkin ? 'bg-white' : 'bg-dark'"
                  >
                    <strong
                      for
                      style="font-size: 18px; white-space: nowrap"
                      class="mr-1"
                      >Select Module</strong
                    >

                    <b-form-select
                      v-model="filterModule.value"
                      :options="filterModule.options"
                      value-field="id"
                      text-field="name"
                      size="sm"
                      style="max-width: 200px"
                      @change="handleFilterChange"
                    />
                  </div>

                  <!-- Filter Change Week -->
                  <div
                    v-if="secondFilter.value == 'week'"
                    class="filter-week-report"
                    :class="!isDarkSkin ? 'bg-white' : 'bg-dark'"
                  >
                    <b-button
                      variant="primary"
                      class="btn-icon arrow-button"
                      @click="changeWeek(-1)"
                    >
                      <feather-icon icon="ChevronLeftIcon" />
                    </b-button>
                    <span
                      style="font-size: 15px"
                      class="filter-week-text"
                      :style="!isDarkSkin ? 'border:1px solid #BDBDBD;' : ''"
                    >
                      {{ paramsWeek.from | myGlobal }} -
                      {{ paramsWeek.to | myGlobal }}
                    </span>

                    <b-button
                      variant="primary"
                      class="btn-icon arrow-button btn-primary"
                      :disabled="disabledBtnEmployees"
                      @click="changeWeek(1)"
                    >
                      <feather-icon icon="ChevronRightIcon" />
                    </b-button>
                  </div>

                  <!-- Filter Change Day -->
                  <b-form-group
                    v-if="filterDay"
                    label="Start Day"
                    label-for="start_day"
                    class="day-filter"
                    style="width: 16em"
                  >
                    <b-form-datepicker
                      id="start_day"
                      v-model="paramsWeek.from"
                      class="custom-date-picker"
                      :style="
                        !isDarkSkin
                          ? 'border:1px solid #BDBDBD;'
                          : 'border:0px;background: #292C33;'
                      "
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      @input="onDateSelected"
                    >
                      <template #button-content>
                        <svg
                          width="17"
                          height="19"
                          viewBox="0 0 17 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="calendar-icon"
                        >
                          <path
                            d="M14.3333 3H2.66667C1.74619 3 1 3.74619 1 4.66667V16.3333C1 17.2538 1.74619 18 2.66667 18H14.3333C15.2538 18 16 17.2538 16 16.3333V4.66667C16 3.74619 15.2538 3 14.3333 3Z"
                            stroke="#656565"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 1V4"
                            stroke="#656565"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5 1V4"
                            stroke="#656565"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 8H16"
                            stroke="#656565"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </template>
                    </b-form-datepicker>
                  </b-form-group>
                  <b-form-group
                    v-if="filterDay"
                    label="End Date"
                    label-for="end_day"
                    class="day-filter"
                    style="width: 16em"
                  >
                    <b-form-datepicker
                      id="end_day"
                      v-model="paramsWeek.to"
                      class="custom-date-picker"
                      :style="
                        !isDarkSkin
                          ? 'border:1px solid #BDBDBD;'
                          : 'border:0px;background: #292C33;'
                      "
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      @input="onDateSelected"
                    >
                      <template #button-content>
                        <svg
                          class="calendar-icon"
                          width="17"
                          height="19"
                          viewBox="0 0 17 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.3333 3H2.66667C1.74619 3 1 3.74619 1 4.66667V16.3333C1 17.2538 1.74619 18 2.66667 18H14.3333C15.2538 18 16 17.2538 16 16.3333V4.66667C16 3.74619 15.2538 3 14.3333 3Z"
                            stroke="#656565"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 1V4"
                            stroke="#656565"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5 1V4"
                            stroke="#656565"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 8H16"
                            stroke="#656565"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </template>
                    </b-form-datepicker>
                  </b-form-group>
                  <!-- Filter Change Month -->
                  <b-form-group
                    v-if="filterMonth"
                    label="Month"
                    label-for="month"
                    class="select-container"
                  >
                    <b-form-select
                      v-model="valueFilterMonth"
                      style="min-width: 120px"
                      :options="months"
                      class="customized-select"
                      :style="
                        !isDarkSkin
                          ? 'border:1px solid #BDBDBD;'
                          : 'border:0px;background: #292C33;'
                      "
                      @input="onDateSelected"
                    />
                  </b-form-group>
                  <!-- Filter Change Year -->
                  <b-form-group
                    v-if="filterMonth"
                    label="Year"
                    label-for="year"
                    class="select-container"
                  >
                    <b-form-select
                      v-model="valueFilterYear"
                      style="min-width: 120px"
                      :options="years"
                      class="customized-select"
                      :style="
                        !isDarkSkin
                          ? 'border:1px solid #BDBDBD;'
                          : 'border:0px;background: #292C33;'
                      "
                      @input="onDateSelected"
                    />
                  </b-form-group>
                </div>
              </div>
              <graphic-marks
                :key="keyChart"
                :attendance="dataGraphic"
                :filters="{
                  day: valueFilterDay,
                  weekStart: paramsWeek.from,
                  weekEnd: paramsWeek.to,
                  month: valueFilterMonth,
                  year: valueFilterYear,
                }"
              />
              <div class="d-flex" style="margin-top: 0rem; width: 100%">
                <div
                  cols="9"
                  :lg="asRRHHOrManagement ? 12 : 12"
                  :md="asRRHHOrManagement ? 12 : 12"
                  class="d-block d-sm-flex align-items-center"
                  style="width: 100%; justify-content: space-between"
                >
                  <div
                    v-if="firstFilter.value == 'graphic'"
                    cols="3"
                    md="6"
                    lg="12"
                    xl="12"
                    class="my-1 rounded py-1 px-2 d-block d-lg-flex align-items-center"
                    style="width: 100%; justify-content: space-between"
                  >
                    <span
                      style="font-size: 15px"
                      class="mr-2 d-flex justify-content-center align-items-center text-center"
                    >
                      Showing
                      <span
                        style="border-radius: 3px; padding: 0 5px; margin: 5px"
                        >{{ startPageGraphic }}</span
                      >
                      to
                      <span
                        style="border-radius: 3px; padding: 0 5px; margin: 5px"
                        >{{ toPageGraphic }}</span
                      >
                      of
                      <span
                        style="border-radius: 3px; padding: 0 5px; margin: 5px"
                        >{{ totalRowsGraphic }}</span
                      >
                      employees
                    </span>
                    <div
                      cols="12"
                      md="auto"
                      class="d-flex justify-content-center justify-content-lg-start text-lg-center"
                    >
                      <b-button
                        :variant="!isDarkSkin ? 'light' : 'dark'"
                        class="btn-icon pagination-button"
                        :style="
                          !isDarkSkin
                            ? 'border:1px solid #BDBDBD;background:white'
                            : 'border:1px solid #373A41!important'
                        "
                        :disabled="disabledBtnBefore"
                        @click="changeEmployees(-1)"
                      >
                        <span class="pagination-icon pagination-item"
                          >Previous</span
                        >
                      </b-button>
                      <div class="pagination-container">
                        <div
                          v-for="item in Math.ceil(totalRowsGraphic / 10)"
                          :id="`page-${item}`"
                          :key="`pagination-${item}`"
                          class="pagination-item"
                          :class="
                            isDarkSkin
                              ? 'pagination-item-dark'
                              : 'pagination-item-light'
                          "
                          :style="getPaginationStyle(item)"
                          @mouseenter="(evt) => hoverPage(evt, item)"
                          @mouseleave="(evt) => leavePage(evt, item)"
                          @click="changePage(item)"
                        >
                          <span>{{ item }}</span>
                        </div>
                      </div>
                      <b-button
                        :variant="!isDarkSkin ? 'light' : 'dark'"
                        class="btn-icon pagination-button"
                        :disabled="disabledBtnNext"
                        :style="
                          !isDarkSkin
                            ? 'border:1px solid #BDBDBD;background:white'
                            : 'border:1px solid #373A41!important'
                        "
                        @click="changeEmployees(1)"
                      >
                        <span class="pagination-icon pagination-item"
                          >Next</span
                        >
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>

            <div class="marks-divider" />

            <b-col class="pie-chart">
              <div>
                <b-overlay :variant="skin">
                  <!-- GRAPHICS PIE EMPLOYEES -->
                  <graphic-pie
                    v-if="firstFilter.value == 'graphic'"
                    :key="keyChartPieTrue"
                    :value-filter-day="valueFilterDay"
                    :value-filter-month="valueFilterMonth"
                    :value-filter-year="valueFilterYear"
                    :value-filter-week-start="paramsWeek.from"
                    :value-filter-week-end="paramsWeek.to"
                    :value-filter-module="filterModule.value"
                  />
                </b-overlay>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <hr
          class="delimiter"
          style="border: 0.5px solid #0090e7; width: 100%"
        />

        <b-col cols="12">
          <!-- GRAPHICS FROM ATTENDANCE -->
          <div class="acumulated-hours">
            <span>Accumulated hours</span>
          </div>
          <vue-apex-charts
            :key="keyChart"
            type="bar"
            height="500"
            :options="chartOptions"
            :series="series"
            theme="theme-color"
          />
        </b-col>
      </b-row>

      <indicators-attendance
        v-if="firstFilter.value == 'report'"
        :key="keyIndicators"
        :value-filter-day="valueFilterDay"
        :value-filter-month="valueFilterMonth"
        :value-filter-year="valueFilterYear"
        :value-filter-week-start="paramsWeek.from"
        :value-filter-week-end="paramsWeek.to"
        :value-filter-module="moduleToSendAsProp"
      />
    </b-row>
    <modal-overtime-validate
      v-if="showModalOvertime"
      :data-employee="employeeData"
      :value-filter-day="valueFilterDay"
      :value-filter-month="valueFilterMonth"
      :value-filter-year="valueFilterYear"
      :value-filter-week-start="paramsWeek.from"
      :value-filter-week-end="paramsWeek.to"
      @closing="showModalOvertime = false"
      @refresh="refreshTable"
    />
    <modal-add-hours
      v-if="showAddHours"
      :employee_id="null"
      :value-filter-month="valueFilterMonth"
      :value-filter-year="valueFilterYear"
      @hidden="closeModalAddHours"
    />
    <modal-add-marks
      v-if="showAddMarks"
      @hidden="closeModalAddMarks"
      @refresh="refreshTable"
    />

    <modal-edit-total-hours-worked
      v-if="showModalEditTotalHoursWorked"
      :employee_id="employee_id"
      :month="valueFilterMonth"
      :year="valueFilterYear"
      :user_id="currentUser.user_id"
      :total_hours="total_hours"
      @hidden="showModalEditTotalHoursWorked = false"
      @refresh="refreshTable"
    />
    <modal-add-marks-tracking
      v-if="addMarksTrackingModal"
      :mng_report="
        firstFilter.value == 'report' && isManagementAmgAttendance ? 1 : 0
      "
      @hidden="hiddenAddMarksTracking"
    />
    <modal-details-employee
      v-if="showDetailsEmployee"
      :second-filter="secondFilter"
      :string-type-view-detail="typeViewDetail"
      :name-employee="employee"
      :filters-mark="{
        userId: userId,
        day: valueFilterDay,
        weekStart: paramsWeek.from,
        weekEnd: paramsWeek.to,
        month: valueFilterMonth,
        year: valueFilterYear,
      }"
      :filters-grid="{
        userId: user_id_month,
        month: valueFilterMonth,
        year: valueFilterYear,
      }"
      @hidden="showDetailsEmployee = false"
    />
  </section>
</template>

<script>
import moment from "moment";
import { VueAutosuggest } from "vue-autosuggest";
import DashboardServices from "@/views/assistance/views/dashboard/services/dashboard.services";
import fields from "@/views/assistance/views/dashboard/data/fields-chief.data";
import fieldsDetails from "@/views/assistance/views/dashboard/data/fields-details.data";
import IndicatorsAttendance from "@/views/assistance/views/dashboard/components/IndicatorsAttendance.vue";
import ModalOvertimeValidate from "@/views/assistance/views/dashboard/components/ModalOvertimeValidate.vue";
import VueApexCharts from "vue-apexcharts";
import GraphicPie from "@/views/assistance/views/dashboard/components/GraphicPie.vue";
import { mapGetters, mapActions } from "vuex";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import ECharts from "vue-echarts";
import theme from "@/views/social-network/views/dashboard2/components/chard/theme.json";
import ModalAddHours from "@/views/assistance/views/dashboard/components/Overtime/ModalAddHours.vue";
import PasswordAppHandler from "@/views/assistance/views/dashboard/components/PasswordAppHandler.vue";
import ModalAddMarks from "@/views/assistance/views/dashboard/components/ModalAddMarks.vue";
import ModalEditTotalHoursWorked from "@/views/assistance/views/dashboard/components/ModalEditTotalHoursWorked.vue";
import GraphicMarks from "@/views/assistance/views/dashboard/components/graphicMarks/GraphicMarks.vue";
import ModalAddMarksTracking from "@/views/assistance/views/dashboard/components/ModalAddMarksTracking.vue";
import CustomizedRadioGroup from "@/commons/CustomizedRadioGroup.vue";
import ModalDetailsEmployee from "@/views/assistance/views/dashboard/components/ModalDetailsEmployee.vue";

ECharts.registerTheme("theme-color", theme);

export default {
  components: {
    IndicatorsAttendance,
    ModalOvertimeValidate,
    VueApexCharts,
    GraphicPie,
    VueAutosuggest,
    ModalAddHours,
    PasswordAppHandler,
    ModalAddMarks,
    ModalEditTotalHoursWorked,
    GraphicMarks,
    ModalAddMarksTracking,
    CustomizedRadioGroup,
    ModalDetailsEmployee,
  },
  data() {
    return {
      generatingPayrollPayments: false,
      keyGrid: 0,
      keyChart: 0,
      keyChartPie: 0,
      keyChartPieTrue: 0,
      keyIndicators: 0,
      addMarksTrackingModal: false,
      firstFilter: {
        options: [
          {
            text: "Report",
            value: "report",
          },
          {
            text: "Graphic",
            value: "graphic",
          },
        ],
        value: "report",
      },
      dataGrid: null,
      dataGraphic: null,
      dataGraphicPie: null,
      secondFilter: {
        options: [
          {
            text: "Day",
            value: "day",
          },
          {
            text: "Week",
            value: "week",
          },
          {
            text: "Month",
            value: "month",
          },
        ],
        value: "week",
      },
      filterModule: {
        options: [],
        value: null,
      },
      dateSelected: {
        from: "",
        to: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      fields,
      fieldsDetails,
      isBusy: false,
      totalRows: 0,
      totalRowsGraphic: 0,
      startPage: 0,
      startPageGraphic: null,
      toPage: 0,
      toPageGraphic: null,
      sortBy: "",
      sortDesc: true,
      detailsEmployee: [],
      filterDay: false,
      filterWeek: true,
      filterMonth: false,
      filterYear: false,
      valueFilterDay: null,
      valueFilterMonth: null,
      valueFilterYear: null,
      selected: null,
      chartOptions: {
        chart: {
          type: "bar",
          height: 500,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        tooltip: {
          enabled: true,
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6D7377",
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          type: "date",
          categories: [],
          labels: {
            show: true,
            style: {
              colors: "#6e6b7b",
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: "#6e6b7b",
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 15,
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: 300,
          textAnchor: "16px",
          labels: { colors: true },
        },
        fill: {
          opacity: 1,
        },
      },
      series: [
        {
          name: "Accumulated Hours",
          data: [],
        },
        {
          name: "Justified hours",
          data: [],
        },
      ],
      months: [],
      years: [],
      sumaHoras: null,
      showDetailsIcon: false,
      showModalAbsence: false,
      showModalIncosistencies: false,
      showModalBirthday: false,
      showModalMedicalRest: false,
      employeeData: null,
      showModalOvertime: false,
      user_id_month: null,
      paramsWeek: {
        from: moment()
          .clone()
          .startOf("isoWeek")
          .isoWeekday(1)
          .format("YYYY-MM-DD"),
        to: moment()
          .clone()
          .endOf("isoWeek")
          .isoWeekday(7)
          .format("YYYY-MM-DD"),
      },
      disabledWeek: true,
      currentWeek: {
        start: moment().startOf("week").add(1, "days").format("YYYY-MM-DD"),
        end: moment().endOf("week").add(1, "days").format("YYYY-MM-DD"),
      },
      otherCalculations: {
        totalHoursByDay: 0,
        overtimeByDay: 0,
        hoursWorkedByFilter: 0,
        accumulatedHoursByFilter: 0,
        overtimeByFilter: 0,
      },
      disabledBtnEmployees: true,
      lastPage: 0,
      loadingExcel: false,
      filteredOptions: [],
      employeeId: null,
      employeeName: null,
      showAddHours: false,
      typeViewDetail: "calendar",
      hour_holidays: 0,
      showAddMarks: false,
      showModalEditTotalHoursWorked: false,
      total_hours: null,
      userId: null,
      employee: null,
      showDetailsEmployee: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
      hoursCounter: "AttendanceStore/G_HOURS_COUNTER",
    }),
    hoursWorkedByFilter() {
      if (this.secondFilter.value === "week") {
        // hours to be work by week
        this.hoursCounter.workingHours = 46;
        // this.setHoursCounter(this.hoursCounter);
        return 46;
      }
      if (this.secondFilter.value === "day") {
        this.hoursCounter.workingHours = 8;

        return 8;
      }
      // hours to be work by month
      let hours = 0;
      const lastDayMonth = moment(
        `${this.valueFilterYear}-${this.valueFilterMonth}`,
        "YYYY-MM-DD"
      ).daysInMonth();

      for (let index = 1; index <= lastDayMonth; index += 1) {
        const dateOfWeek = moment(
          `${this.valueFilterYear}-${this.valueFilterMonth + 1}-${index}`,
          "YYYY-MM-DD"
        ).format("dddd");
        if (dateOfWeek.toLowerCase() === "saturday") {
          hours += 6;
        } else if (dateOfWeek.toLowerCase() !== "sunday") {
          hours += 8;
        }
      }
      this.hoursCounter.workingHours = hours;

      return hours;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isRRhh() {
      return this.moduleId === 17;
    },
    isParagon() {
      return this.moduleId === 12;
    },
    isManagement() {
      return this.moduleId === 16;
    },
    disabledBtnNext() {
      return this.paginate.currentPage === this.lastPage;
    },
    disabledBtnBefore() {
      return this.paginate.currentPage === 1;
    },
    isFilterMonth() {
      return this.secondFilter.value === "month";
    },
    isNotRRHHorManagement() {
      return !this.isRRhh && !this.isManagement;
    },
    asChief() {
      // If is rrhh or management and tabRole is chief will access as chief role, in the other modules that are not rrhh or management will access as chief too
      return (
        ((this.isRRhh || this.isManagement) &&
          this.$route.meta.tabRole === "chief") ||
        this.isNotRRHHorManagement
      );
    },
    asParagonChief() {
      /*
      return this.isParagon && this.currentUser.role_id === 2;
      */
      return this.isParagon && this.currentUser.role_id === 17;
    },
    asRRHHOrManagement() {
      return (
        (this.isRRhh || this.isManagement) &&
        this.$route.meta.tabRole !== "chief"
      );
    },
    isManagementAmgAttendance() {
      return this.isManagement && this.$route.meta.tabRole === "mng";
    },
    moduleToSendAsProp() {
      return this.asRRHHOrManagement ? this.filterModule.value : this.moduleId;
    },
  },

  watch: {
    skin(oldVal, newVal) {
      if (newVal == "light") {
        this.chartOptions.xaxis.labels.style.colors = "#d8d8d8";
        this.chartOptions.yaxis.labels.style.colors = "#d8d8d8";
      } else {
        this.chartOptions.xaxis.labels.style.colors = "#6e6b7b";
        this.chartOptions.yaxis.labels.style.colors = "#6e6b7b";
      }
      this.keyChart += 1;
      const color = this.isDarkSkin ? "#F7F7F7" : "#000000";
      const backgroundColor = this.isDarkSkin ? "#3A3A3A" : "#ffffff";
      const options = this.chartOptions;
      options.chart.events = {
        dataPointMouseEnter: (event, chartContext) => {
          const tooltip = chartContext.el.querySelector(".apexcharts-tooltip");
          const tooltipTitle = chartContext.el.querySelector(
            ".apexcharts-tooltip-title"
          );
          if (tooltip) {
            tooltip.style.backgroundColor = backgroundColor;
            tooltip.style.color = color;
            tooltip.style.border = "1px solid #000000";
          }
          if (tooltipTitle) {
            tooltipTitle.style.backgroundColor = backgroundColor;
            tooltipTitle.style.color = color;
            tooltipTitle.style.borderBottom = "1px solid #000000";
          }
        },
      };
    },
    keyChartPie(oldVal, newVal) {
      if (oldVal > 2) {
        this.keyChartPieTrue += 1;
      }
    },
    valueFilterYear(oldVal) {
      this.getMonthsToFilter(oldVal);
    },
    "firstFilter.value": {
      handler() {
        this.keyChartPie = 0;
        this.onDateSelected();
      },
      immediate: true,
    },
    "filterModule.value": function () {
      this.keyChartPie += 1;
      this.$refs.autocomplete.selected = null;
      this.$refs.autocomplete.internalValue = "";
    },
  },

  async mounted() {
    await this.onDateSelected();
    await this.getModules();
    this.getMonthsToFilter();
    this.getYearsFilter();
    this.chartOptions.xaxis.labels.style.colors = this.isDarkSkin
      ? "#6e6b7b"
      : "#d8d8d8";
    if (this.isDarkSkin) {
      this.chartOptions.xaxis.labels.style.colors = "white";
      this.chartOptions.yaxis.labels.style.colors = "white";
    } else {
      this.chartOptions.xaxis.labels.style.colors = "black";
      this.chartOptions.yaxis.labels.style.colors = "black";
    }
    this.keyChart++;
  },
  async created() {
    this.initChart();

    this.chartOptions.xaxis.labels.style.colors = this.isDarkSkin
      ? "#6e6b7b"
      : "#d8d8d8";
    const overtimePending = this.fields.findIndex(
      (field) => field.key === "overtime_pending"
    );
    this.fields[overtimePending].visible = this.asChief; // will be visible if disapproved
  },
  methods: {
    ...mapActions({
      setHoursCounter: "AttendanceStore/A_SET_HOURS_COUNTER",
    }),
    showAddMarksTracking() {
      this.addMarksTrackingModal = true;
    },
    hiddenAddMarksTracking() {
      this.addMarksTrackingModal = false;
    },
    toggleDetails(row) {
      this.userId = row.user_id;
      this.employee = row.employee;
      this.showDetailsEmployee = true;
      this.user_id_month = row.user_id;
      const filters = this.dataGrid.filter((item) => item._showDetails);
      filters.forEach((item) => {
        if (item !== row) {
          item.showDetailsEmployee = false;
        }
      });
      // row.toggleDetails();
    },
    typeView(type) {
      this.typeViewDetail = type.toLowerCase();
      this.keyGrid += 1;
    },
    showAllHoursToApproved() {
      this.showModalOvertime = true;
      this.employeeData = null;
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.value;
    },
    onInputChange(text) {
      this.employeeId = null;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        return;
      }
      const params = {
        employee_name: text,
        module_id: this.filterModule.value,
      };
      DashboardServices.getEmployeeByModule(params).then((response) => {
        if (response.status === 200) {
          this.filteredOptions = [{ data: [...response.data] }];
        }
      });
    },
    selectHandler(value) {
      this.employeeId = value.item.id;
      this.employeeName = value.item.value;
    },
    async addOvertime() {
      this.showAddHours = true;
    },
    addMarks() {
      this.showAddMarks = true;
    },
    closeModalAddHours() {
      this.showAddHours = false;
    },
    closeModalAddMarks() {
      this.showAddMarks = false;
    },
    initChart() {
      const options = this.chartOptions;
      const color = this.isDarkSkin ? "#F7F7F7" : "#000000";
      const backgroundColor = this.isDarkSkin ? "#3A3A3A" : "#ffffff";
      options.chart.events = {
        dataPointMouseEnter: (event, chartContext) => {
          const tooltip = chartContext.el.querySelector(".apexcharts-tooltip");
          const tooltipTitle = chartContext.el.querySelector(
            ".apexcharts-tooltip-title"
          );
          if (tooltip) {
            tooltip.style.backgroundColor = backgroundColor;
            tooltip.style.color = color;
            tooltip.style.border = "1px solid #000000";
          }
          if (tooltipTitle) {
            tooltipTitle.style.backgroundColor = backgroundColor;
            tooltipTitle.style.color = color;
            tooltipTitle.style.borderBottom = "1px solid #000000";
          }
        },
      };
    },
    getMonthsToFilter(year) {
      this.months = [];
      let currentMonth = moment().format("MM");
      const currentYear = moment().format("YYYY");
      const monthFormat = "MMMM";
      const yearFormat = "YYYY";
      const currentYearFormated = moment(currentYear.toString(), "YYYY").format(
        yearFormat
      );
      if (
        currentYearFormated !== year &&
        typeof year !== "undefined" &&
        year != null
      ) {
        currentMonth = 12;
      }

      for (let month = 1; month <= currentMonth; month += 1) {
        const monthObject = {
          text: moment(month.toString(), "MM").format(monthFormat),
          value: month.toString().padStart(2, "0"),
        };
        this.months.push(monthObject);
      }
    },

    getYearsFilter() {
      const currentYear = moment().format("YYYY");
      const yearFormat = "YYYY";

      for (let year = 2023; year <= currentYear; year += 1) {
        const yearObject = {
          text: moment(year.toString(), "YYYY").format(yearFormat),
          value: year.toString().padStart(2, "0"),
        };
        this.years.push(yearObject);
      }
      this.valueFilterYear = currentYear;
    },
    openModalOvertime(item) {
      this.showModalOvertime = true;
      this.employeeData = item;
    },
    async providerDetailsEmployeeComputed(userId) {
      this.userId_month = userId;
      try {
        const params = {
          userId,
          filterDay: this.valueFilterDay,
          filterWeekStart: this.paramsWeek.from,
          filterWeekEnd: this.paramsWeek.to,
          filterMonth: this.valueFilterMonth,
        };
        this.detailsEmployee = [];
        const response = await DashboardServices.getDetailsAttendance(params);
        if (response.status === 200) {
          this.detailsEmployee.forEach((element) => {
            delete element.cc;
            delete element.employee;
            delete element.primera_marca_redondeada;
            delete element.ultima_marca_redondeada;
          });
          this.detailsEmployee = response.data.data;
          this.sumaHoras = response.data.sumaHoras;
          this.showDetailsIcon = true;
          return this.detailsEmployee;
        }
      } catch (error) {
        console.log(`xd${error}`);
      }
      return [];
    },
    refreshTable() {
      if (this.firstFilter.value === "report" && this.$refs.refEmployeesList) {
        this.$refs.refEmployeesList.refresh();
      }
    },
    async onDateSelected() {
      if (this.firstFilter.value === "graphic") {
        await this.getGraphics();
      } else {
        this.refreshTable();
        this.keyIndicators += 1;
      }
    },

    async handleFilterChange(value) {
      if (value) {
        this.secondFilter.value = value;
      }
      if (this.secondFilter.value === "day") {
        this.filterDay = true;
        this.valueFilterDay = null;
        this.filterMonth = false;
        this.filterWeek = false;
        this.filterYear = false;
        this.paramsWeek.from = moment().format("YYYY-MM-DD");
        this.paramsWeek.to = moment().format("YYYY-MM-DD");

        this.valueFilterMonth = null;
        this.valueFilterYear = null;
        await this.onDateSelected();
      } else if (this.secondFilter.value === "week") {
        this.filterWeek = true;
        this.valueFilterDay = null;
        this.valueFilterMonth = null;
        this.valueFilterYear = null;
        this.paramsWeek.from = moment()
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD");
        this.paramsWeek.to = moment()
          .endOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD");
        this.filterMonth = false;
        this.filterDay = false;
        this.filterYear = false;
        await this.onDateSelected();
      } else {
        this.filterMonth = true;
        this.valueFilterDay = null;
        this.paramsWeek.from = null;
        this.paramsWeek.to = null;
        this.valueFilterMonth = moment().format("MM");
        this.valueFilterYear = moment().format("YYYY");
        this.filterWeek = false;
        this.filterDay = false;
        this.filterYear = false;
        await this.onDateSelected();
      }
    },
    async employeesReportProvider(ctx) {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sortBy: ctx.sortBy,
          sortOrder: ctx.sortDesc ? "ASC" : "DESC",
          orderBy: 10,
          order: this.firstFilter.value === "report" ? "DESC" : "ASC",
          module_id: this.asRRHHOrManagement
            ? this.filterModule.value
            : this.moduleId,
          user_id: null,
          filterDay: this.valueFilterDay,
          filterWeekStart: this.paramsWeek.from,
          filterWeekEnd: this.paramsWeek.to,
          filterMonth: this.valueFilterMonth,
          filterYear: this.valueFilterYear,
          tab:
            this.isRRhh || this.isManagement
              ? this.$route.meta.tabRole
              : "chief",
          view:
            this.firstFilter.value === "report"
              ? "overtime DESC"
              : "employee_name ASC",
        };
        this.addPreloader();
        const data = await DashboardServices.getOvertimeAttendance(params);
        if (data.status === 200) {
          this.dataGrid = data.data.data;
          this.dataGrid.forEach((row) => {
            row.showDetailsIcon = false;
          });
          this.totalRows = data.data.total;
          this.startPage = data.data.from || 0;
          this.toPage = data.data.to || 0;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;
          this.removePreloader();
          if (this.dataGrid && this.dataGrid.length > 0) {
            this.hoursCounter.holidaysHours = this.dataGrid[0].hours_holyday;
            this.hour_holidays = this.dataGrid[0].hours_holyday;
          }

          return this.dataGrid || [];
        }
      } catch (error) {
        this.showErrorSwal(`provider${error}`);
        console.error(error);
      }
      return [];
    },

    async getGraphics() {
      this.addPreloader();
      try {
        const params = {
          name_text: "",
          perPage: 10,
          page: this.paginate.currentPage,
          sortBy: null,
          sortOrder: "ASC",
          orderBy: 10,
          order: "DESC",
          module_id: this.asRRHHOrManagement
            ? this.filterModule.value
            : this.moduleId,
          user_id: null,
          filterDay: this.valueFilterDay,
          filterWeekStart: this.paramsWeek.from,
          filterWeekEnd: this.paramsWeek.to,
          filterMonth: this.valueFilterMonth,
          filterYear: this.valueFilterYear,
          tab:
            this.isRRhh || this.isManagement
              ? this.$route.meta.tabRole
              : "chief",
          view:
            this.firstFilter.value === "report"
              ? "overtime DESC"
              : "employee_name ASC",
        };

        const data = await DashboardServices.getOvertimeAttendance(params);
        this.dataGraphic = data.data.data;
        this.totalRowsGraphic = data.data.total;
        this.startPageGraphic = data.data.from || 0;
        this.toPageGraphic = data.data.to || 0;
        this.lastPage = data.data.last_page;
        this.generateValuesGraphics(this.dataGraphic);
        if (this.dataGraphic && this.dataGraphic.length > 0) {
          this.hoursCounter.holidaysHours = this.dataGraphic[0].hours_holyday;

          this.hour_holidays = this.dataGraphic[0].hours_holyday;
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.log(error);
      }
      this.removePreloader();
    },
    async getModules() {
      const response = await helpdeskService.getModules();
      if (response.status === 200) {
        this.filterModule.options = [{ name: "Select a module", id: null }];
        this.filterModule.options = [
          ...this.filterModule.options,
          ...response.data,
        ];
      }
    },
    generateValuesGraphics(array) {
      try {
        const filterValue = this.secondFilter.value;
        let limit;
        let hoursWorkedByFilter;

        if (filterValue === "week") {
          limit = 46;
          hoursWorkedByFilter = limit;
        } else if (filterValue === "day") {
          limit = 8;
          hoursWorkedByFilter = limit;
        } else {
          limit = 184;
          hoursWorkedByFilter = limit;
        }
        this.series[0].data = array.map((item) => item.working_hours_valid);
        this.series[1].data = array.map(
          (item) => Number(item.overtime_approved) + Number(item.hours_holyday)
        );
        this.chartOptions.xaxis.categories =
          array.length > 0 ? array.map((item) => item.employee) : [];
        this.otherCalculations.hoursWorkedByFilter = hoursWorkedByFilter;
        this.otherCalculations.accumulatedHoursByFilter =
          this.series[0].data.reduce((a, b) => a + b, 0);
        this.otherCalculations.overtimeByFilter = this.series[1].data.reduce(
          (a, b) => a + b,
          0
        );
        this.keyChartPie += 1;
        this.keyChart += 1;
      } catch (error) {
        console.log(`generate values${error}`);
      }
    },
    changeTotalHours(total, employeeId) {
      this.employee_id = employeeId;
      this.total_hours = total;
      this.showModalEditTotalHoursWorked = true;
    },
    separeWeeksOfMonth(array) {
      const diasEnMes = moment(
        `${this.valueFilterYear}-${this.valueFilterMonth + 1}`,
        "YYYY-MM"
      ).daysInMonth();
      const semanas = [];
      const acumulatedHours = [];
      const overtime = [];
      let semanaActual = [];

      let valuesHours = 0;
      let valuesOvertime = 0;
      for (let dia = 1; dia <= diasEnMes; dia += 1) {
        const fecha = moment(
          `${this.valueFilterYear}-${this.valueFilterMonth + 1}-${dia}`,
          "YYYY-MM-DD"
        );
        const numSemana = fecha.week();
        const item = array.find(
          (element) => element.date_mark === fecha.format("YYYY-MM-DD")
        );
        if (numSemana !== semanaActual) {
          semanaActual = numSemana;
          valuesHours = 0;
          valuesOvertime = 0;
          semanas.push([]);
          acumulatedHours.push([]);
          overtime.push([]);
        }

        valuesHours += item ? item.working_hours_rounded : 0;
        valuesOvertime += item ? item.overtime_approved : 0;
        semanas[semanas.length - 1].push(fecha.format("YYYY-MM-DD"));
        acumulatedHours[acumulatedHours.length - 1].push(valuesHours);
        overtime[overtime.length - 1].push(valuesOvertime);
      }

      const optionsWeek = semanas.map((week) => {
        const firstDayWeek = week[0];
        const lastDayWeek = week[week.length - 1];
        return [firstDayWeek, lastDayWeek];
      });

      const optionsValues = acumulatedHours.map((hours) => {
        const lastHour = hours[hours.length - 1];
        return lastHour > 46 ? 46 : lastHour;
      });

      const optionsOvertime = overtime.map((overtimeValue) => {
        const lastOvertime = overtimeValue[overtimeValue.length - 1];
        return lastOvertime;
      });

      return { optionsWeek, optionsValues, optionsOvertime };
    },
    async changeWeek(week) {
      this.paramsWeek.from = moment(this.paramsWeek.from)
        .add(week, "week")
        .format("YYYY-MM-DD 00:00:00");

      this.paramsWeek.to = moment(this.paramsWeek.from)
        .add(6, "days")
        .format("YYYY-MM-DD 00:00:00");

      this.disabledBtnEmployees = this.paramsWeek.to >= this.currentWeek.end;
      await this.onDateSelected();
    },
    async changeEmployees(cant) {
      this.paginate.currentPage += cant;
      await this.onDateSelected();
    },
    async changePage(page) {
      this.paginate.currentPage = page;
      await this.onDateSelected();
    },
    async hoverPage(evt, item) {
      const pageContainer = document.getElementById(`page-${item}`);
      if (pageContainer) {
        if (this.paginate.currentPage !== item) {
          pageContainer.style.backgroundColor = this.isDarkSkin
            ? "#0090E7"
            : "#0090E7";
          pageContainer.style.color = this.isDarkSkin ? "black" : "#ffffff";
        }
      }
    },
    async leavePage(evt, item) {
      const pageContainer = document.getElementById(`page-${item}`);
      if (pageContainer) {
        if (this.paginate.currentPage !== item) {
          pageContainer.style.backgroundColor = this.isDarkSkin
            ? "#373A41"
            : "white";
          pageContainer.style.color = this.isDarkSkin ? "#0090E7" : "#0090E7";
        }
      }
    },
    async donwloadExcel(value) {
      /// 0 for general and 1 for detailedd report

      try {
        this.loadingExcel = true;
        let moduleName;
        if (this.asRRHHOrManagement) {
          if (this.filterModule.value) {
            const foundModule = this.filterModule.options.find(
              (item) => item.id === this.filterModule.value
            );
            moduleName = foundModule?.name || "ALL MODULES";
          } else {
            moduleName = "ALL MODULES";
          }
        } else {
          const foundModule = this.filterModule.options.find(
            (item) => item.id === this.moduleId
          );
          moduleName = foundModule?.name;
        }

        const params = {
          module_name: moduleName,
          filterMonth: this.valueFilterMonth,
          filterYear: this.valueFilterYear,
          module_id: this.asRRHHOrManagement
            ? this.filterModule.value
            : this.moduleId,

          employee_id: this.employeeId ?? null,
          employee_name: this.employeeName ?? null,
        };

        const response =
          value === 0
            ? await DashboardServices.generateAttendanceReportExcel(params)
            : await DashboardServices.generateGeneralAttendanceReportExcel(
                params
              );

        const nameFile = `attendance_report_${moduleName}_${this.valueFilterYear}-${this.valueFilterMonth}.xlsx`;
        await this.forceFileDownload(response, nameFile);

        this.loadingExcel = false;
      } catch (error) {
        this.loadingExcel = false;
        this.showErrorSwal(error);
        console.log(error, "error to download excel");
      }
    },
    getPaginationStyle(item) {
      let style = "";
      if (item === this.paginate.currentPage) {
        if (this.isDarkSkin) {
          style = "background-color: #0090e7!important; color:black!important";
        } else {
          style = "background-color: #0090e7!important; color: white!important";
        }
      } else if (this.isDarkSkin) {
        style = "background-color: #373A41!important; color:#0090e7!important";
      } else {
        style = "background-color: white!important; color: #0090e7!important";
      }
      return style;
    },
  },
};
</script>
<style scoped>
.load {
  animation: spinner 1.5s linear infinite;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
#delimiter-filters {
  height: 46px;
  width: 2px;
  border-left: 2px solid #0090e7;
}
#delimiter-filters-report {
  height: 46px;
  width: 1px;
  border-left: 1px solid #bdbdbd;
}
#filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: auto;
}
.filter-item:last-child {
  margin-left: 1em;
}
#add-hours {
  background-color: #00d9d1 !important;
}
#add-marks,
#marks-tracking {
  background-color: #8f5fe8 !important;
}
#pending-approval {
  background-color: #ffb826 !important;
}

.customized-button.left-item {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin-right: 0.5em;
}
.customized-button.right-item {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding: 0.8em 1em !important;
}
.customized-button span {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}
.customized-button {
  border: 0px !important;
  border-radius: 7px !important;
  padding: 0.8em 1em !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pie-chart {
  width: 22%;
  margin-left: 1em;
}
.marks-title {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-size: 23px;
  line-height: 16px;
  margin-top: 2em;
  margin-left: 2em;
}
.time-filters-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.time-filter {
  display: flex;
  flex-direction: row;
}
.filter-week {
  display: flex;
  flex-direction: row;
  margin-top: 5em;
  margin-right: 3em;
  height: 3em;
}
.filter-week-report {
  display: flex;
  flex-direction: row;
  min-height: 3em;
}
.filter-week-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2em;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}
.arrow-button {
  border-radius: 0px !important;
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}
.arrow-button:nth-child(1) {
  border-radius: 0px !important;
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}
.custom-date-picker {
  display: flex !important;
  flex-direction: row-reverse;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  align-items: center;
  margin-left: 1em;
}
.custom-date-picker:hover .calendar-icon path {
  stroke: black !important;
}
.custom-date-picker:hover {
  border: 1px solid black !important;
}
.day-filter {
  margin-top: 5em;
  margin-right: 1em;
  display: flex !important;
  align-items: center;
  gap: 1em;
  flex-direction: row;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  font-size: 16px !important;
  line-height: 12px;
}
.day-filter-report {
  margin-right: 1em;
  display: flex !important;
  align-items: center;
  gap: 1em;
  flex-direction: row;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
  font-size: 16px !important;
  line-height: 12px;
}
.day-filter-report:last-child {
  margin-right: 0em;
}
.day-filter:last-child {
  margin-right: 0em;
}
.customized-select {
  border: 0px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-size: 400;
  line-height: 12px;
  width: 10em;
}
.select-container {
  margin-top: 5em;
  margin-right: 10px;
}
.select-container:last-child {
  margin-right: 2em;
}
.select-container-report {
  margin-top: 5em;
  margin-right: 10px;
}
.select-container-report:last-child {
  margin-right: 2em;
}
.acumulated-hours {
  width: 100%;
  margin-top: 3em;
  margin-bottom: 1em;
}
.acumulated-hours span {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-size: 23px;
  line-height: 16px;
  margin-left: 2em;
}
.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.pagination-item {
  padding: 0.6em 1em;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 13px;
  text-align: center;
  color: #0090e7;
}
.pagination-item-light {
  border: 1px solid #bdbdbd;
}
.pagination-item-dark {
  border: 1px solid #373a41;
}
.pagination-button {
  color: #1a5276 !important;
  border-radius: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.pagination-button:last-child {
  border-radius: 7px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.marks-divider {
  height: 40em;
  width: 2px;
  border-left: 2px solid #0090e7;
}
@media (max-width: 980px) {
  .marks-divider {
    width: 100vw;
    height: 2px;
    border-top: 2px solid #0090e7;
  }
  .marks-container {
    display: flex;
    flex-direction: column !important;
  }
  .pie-chart {
    width: 100%;
  }
}
</style>
