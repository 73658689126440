<template>
  <b-modal
    ref="edit_total_hours"
    :size="'sm'"
    title="Edit Total Hours Worked"
    header-bg-variant="primary"
    header-text-variant="light"
    body-bg-variant="ligth"
    body-text-variant="dark"
    footer-bg-variant="ligth"
    footer-text-variant="dark"
    no-close-on-backdrop
    class="border-0 border-white"
    @hidden="closeModal"
  >
    <b-container class="p-1">
      <validation-observer ref="formEditTotalHours">
        <b-row>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="Hours Total"
              rules="required"
            >
              <b-form-group label="Hours Total">
                <b-form-input
                  v-model="totalHours"
                  type="number"
                  min="0"
                  max="8"
                  :max-length="2"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger' : null"
                  class="mb-0"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-around align-items-center">
        <b-button
          variant="primary"
          @click="editTotalHours"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import DashboardServices from '@/views/assistance/views/dashboard/services/dashboard.services';

export default {
  props: {
    total_hours: null,
    employee_id: null,
    month: null,
    year: null,
    user_id: null,
  },
  data() {
    return {
      totalHours: null,
    };
  },
  mounted() {
    this.totalHours = this.total_hours;
    this.toggleModal('edit_total_hours');
  },
  methods: {
    closeModal() {
      this.$emit('hidden');
    },
    async editTotalHours() {
      const result = await this.$refs.formEditTotalHours.validate();
      if (!result) return;

      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;

      try {
        const result = await this.$refs.formEditTotalHours.validate();
        if (!result) {
          return;
        }
        this.addPreloader();
        const params = {
          employee_id: this.employee_id === null ? this.employeeId : this.employee_id,
          month: this.month,
          year: this.year,
          user_id: this.user_id,
          total_hours: this.totalHours,
        };
        const { data } = await DashboardServices.editTotalHoursWorked(params);
        if (data.status === 200) {
          this.showSuccessSwal('Success', 'Hour registered successfully');
          this.$refs.formEditTotalHours.reset();
          this.closeModal();
          this.$emit('refresh');
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log(error);
      }
    },
  },
};
</script>
