<template>
  <div>
    <div>
      <h3 class="text-center">
        Justifications Pending Approval
      </h3>
    </div>
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :no-visible-principal-filter="dataEmployee == null ? false : true"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      :change-columns-by-client="true"
      @reload="refreshTable()"
    >
      <b-table
        slot="table"
        ref="refOvertimeList"
        :items="overtimeProvider"
        :fields="visibleFields"
        primary-key="id"
        table-class="text-nowrap"
        sticky-header="45vh"
        show-empty
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        class="table-scroll-per"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <!-- template actions -->
        <template #cell(actions)="data">
          <div class="d-flex align-items-start flex-column">
            <b-form-group>
              <b-form-checkbox
                v-if="data.item.justification != null"
                v-model="data.item.validated"
                checked="1"
                value="1"
                @change="onChangeCheck(data.item)"
              >Validate</b-form-checkbox>
              <b-form-checkbox
                v-model="data.item.validated"
                checked="2"
                value="2"
                @change="onChangeCheck(data.item)"
              >Not validate</b-form-checkbox>
            </b-form-group>
          </div>
        </template>

        <template #cell(overtime)="data">
          <div class="d-flex justify-content-center">
            <span
              class="text-primary"
              style="padding: 0 10px; border-radius: 5px"
            >
              {{ data.item.overtime }}
            </span>
          </div>
        </template>
        <template #cell(date_mark)="data">
          <div v-if="data.item.date_mark">
            <div
              v-for="(date, index) in JSON.parse(data.item.date_mark)"
              v-show="data.item.opened || index < 3"
              :key="index"
            >
              <span>{{ date | myGlobal }}</span>
            </div>
            <div
              v-if="
                data.item.opened == false &&
                  JSON.parse(data.item.date_mark).length > 3
              "
            >
              <a
                style="cursor: pointer"
                href="#"
                @click="data.item.opened = true"
              >See more...</a>
            </div>
            <div
              v-if="
                data.item.opened == true &&
                  JSON.parse(data.item.date_mark).length > 3
              "
            >
              <a
                style="cursor: pointer"
                href="#"
                @click="data.item.opened = false"
              >See less...</a>
            </div>
          </div>
        </template>
        <template #cell(observation)="data">
          <div class="d-flex justify-content-center">
            <validation-observer ref="formJustification">
              <validation-provider
                v-slot="{ errors }"
                name="motive"
                rules="max:200"
              >
                <b-form-textarea
                  id="textarea-small"
                  v-model="data.item.motive"
                  size="sm"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger' : ''"
                  :disabled="
                    data.item.validated == 1 ||
                      data.item.validated == null ||
                      data.item.validated == 0
                  "
                  placeholder="Write a motive for disapproving these hours"
                />
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </validation-observer>
          </div>
        </template>
        <template #cell(justification)="data">
          <div class="d-flex justify-content-center">
            <b-button
              v-if="data.item.justification == null"
              size="sm"
              variant="primary"
              @click="addJustification(data.item)"
            >
              <tabler-icon
                icon="PlusIcon"
                size="15"
              />
            </b-button>
            <span
              v-else-if="data.item.type_justification == 4"
              style="text-align: center; cursor: pointer"
              @click="showModalMedicalRest(data.item)"
            >

              <tabler-icon
                icon="FileIcon"
                size="21"
                badge-classes="bg-primary"
              />
              <br>
              {{ data.item.name_type_justification }}
            </span>
            <span
              v-else
              style="text-align: center; cursor: pointer"
              @click="editJustification(data.item)"
            >
              <tabler-icon
                icon="FileIcon"
                size="21"
                badge-classes="bg-primary"
              />
              <br>
              {{ data.item.name_type_justification }}
            </span>
          </div>
        </template>
        <template #custom-foot>
          <b-tr class="bg-dark">
            <b-th />
            <b-th v-if="dataEmployee == null" />
            <b-th class="footer-dark">
              <div class="footer-span">
                <span class="font-small-3">{{ overtime }} hour(s)</span>
              </div>
              <br>
            </b-th>
            <b-th />
            <b-th class="footer-dark">
              <div class="footer-span checkbox">
                <span class="font-small-3">
                  <label class="checkbox-container">
                    <input
                      id="validate-checkbox"
                      v-model="validateAll"
                      type="checkbox"
                      class="custom-checkbox blue"
                      style="display: none"
                      @change="markCheckboxAll()"
                    >
                    <span class="checkmark" />
                    <span style="color:black">Validate All</span>
                  </label>
                </span>
              </div>
              <br>
              <div class="footer-span checkbox">
                <span class="font-small-3">
                  <label class="checkbox-container">
                    <input
                      id="validate-checkbox"
                      v-model="notValidateAll"
                      type="checkbox"
                      class="custom-checkbox blue"
                      style="display: none"
                      @change="markCheckboxAllNotValidate()"
                    >
                    <span class="checkmark" />
                    <span style="color:black">Not Validate All</span>
                  </label>
                </span>
              </div>
            </b-th>
            <b-th />
          </b-tr>
        </template>
      </b-table>
    </filter-slot>
    <modal-justification
      v-if="showUpload"
      :employee_id="employee_id"
      :data-justification="dataJustification"
      :date_overtime="date_overtime"
      :action="actionJustification"
      @hidden="closeModalJustification"
      @refreshData="refreshTable"
    />
    <modal-upload-medical-rest
      v-if="showUploadMedicalRest"
      :employee_id="employee_id"
      :data-justification="dataJustification"
      :action="'show'"
      @closeModal="closeUploadMedicalRest"
      @refreshData="refreshTable"
    />

  </div>

</template>

<script>
import DashboardServices from '@/views/assistance/views/dashboard/services/dashboard.services';
import ModalJustification from '@/views/assistance/views/dashboard/components/ModalJustification.vue';
import ModalUploadMedicalRest from '@/views/assistance/views/dashboard/components/medicalRest/modalUploadMedicalRest.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  components: {
    ModalJustification,
    ModalUploadMedicalRest,
  },
  props: {
    dataEmployee: null,
    valueFilterDay: null,
    valueFilterWeekStart: null,
    valueFilterWeekEnd: null,
    valueFilterMonth: null,
    valueFilterYear: null,
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      filters: [
        {
          type: 'select',
          margin: true,
          showLabel: true,
          label: 'Month',
          model: null,
          options: [
            { value: null, label: 'Select a month' },
            { value: 1, label: 'January' },
            { value: 2, label: 'February' },
            { value: 3, label: 'March' },
            { value: 4, label: 'April' },
            { value: 5, label: 'May' },
            { value: 6, label: 'June' },
            { value: 7, label: 'July' },
            { value: 8, label: 'August' },
            { value: 9, label: 'September' },
            { value: 10, label: 'October' },
            { value: 11, label: 'November' },
            { value: 12, label: 'December' },
          ],
          reduce: 'value',
          selectText: 'label',
          cols: 12,
          visible: true,
        },
      ],
      isBusy: false,
      totalRows: 0,
      startPage: null,
      toPage: null,
      sortBy: '',
      sortDesc: true,
      fields: [
        {
          key: 'employee_name',
          label: 'Name',
        },
        {
          key: 'date_mark',
          label: 'Date',
          visible: false,
        },
        {
          key: 'overtime',
          label: 'Hours',
          thClass: 'text-center',
          visible: false,
        },
        {
          key: 'justification',
          label: 'Justification',
          thClass: 'text-center',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
        {
          key: 'observation',
          label: 'Motive',
          thClass: 'text-center',
          visible: false,
        },
      ],
      listEmployeeOvertime: [],
      validateAll: false,
      notValidateAll: false,
      showAll: false,
      date_overtime: null,
      showUpload: false,
      showUploadMedicalRest: false,
      dataValidated: [],
      dataNotValidated: [],
      actionJustification: null,
      employee_id: null,

    };
  },
  computed: {
    overtime() {
      return this.listEmployeeOvertime
        .map(item => parseInt(item.overtime, 10))
        .reduce((a, b) => (a || 0) + (b || 0), 0);
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    visibleFields() {
      if (this.dataEmployee != null) {
        // Clonar el array original para evitar modificarlo directamente
        const fieldsCopy = [...this.fields];
        const nameColumnIndex = fieldsCopy.findIndex(
          field => field.key === 'employee_name',
        );
        if (nameColumnIndex !== -1) {
          fieldsCopy.splice(nameColumnIndex, 1); // Eliminar la columna "Name"
        }
        return fieldsCopy;
      }
      return this.fields;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isRRhh() {
      return this.moduleId === 17;
    },
    isManagement() {
      return this.moduleId === 16;
    },
    asChief() {
      // If is rrhh or management and tabRole is chief will access as chief role, in the other modules that are not rrhh or management will access as chief too
      return (
        ((this.isRRhh || this.isManagement)
          && this.$route.meta.tabRole === 'chief')
        || this.isNotRRHHorManagement
      );
    },
  },

  methods: {
    closeUploadMedicalRest() {
      this.showUploadMedicalRest = false;
    },
    async overtimeProvider(ctx) {

      try {
        const params = {
          name_text: this.filterPrincipal.model,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sortBy: ctx.sortBy,
          sortOrder: ctx.sortDesc ? 'ASC' : 'DESC',
          orderBy: 10,
          order: 'DESC',
          user_id: this.dataEmployee != null ? this.dataEmployee.user_id : null,
          filterDay: this.dataEmployee != null ? this.valueFilterDay : null,
          filterWeekStart:
            this.dataEmployee != null ? this.valueFilterWeekStart : null,
          filterWeekEnd:
            this.dataEmployee != null ? this.valueFilterWeekEnd : null,
          // obtener mes actual
          filterMonth: this.dataEmployee != null
            ? this.valueFilterMonth
            : ((this.isRRhh || this.isManagement) && moment().date() <= 5
              ? moment().subtract(1, 'month').format('MM')
              : moment().format('MM')),
          filterYear:
            this.dataEmployee != null
              ? this.valueFilterYear
              : moment().format('YYYY'),
          module: this.moduleId,
        };
        const data = await DashboardServices.getListEmployeeOvertime(params);
        if (data.status === 200) {
          this.listEmployeeOvertime = data.data.data;
          this.totalRows = data.data.total;
          this.startPage = data.data.from || 0;
          this.toPage = data.data.to || 0;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;
          this.listEmployeeOvertime.showAll = false;
          this.perPage = 10;
          this.listEmployeeOvertime.forEach(item => {
            this.$set(item, 'opened', false);
            this.$set(item, 'motive', '');
            this.$set(item, 'validated', false);
          });
          return this.listEmployeeOvertime || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
      return [];
    },
    async closeModalJustification() {
      this.showUpload = false;
    },
    async refreshTable() {
      this.$refs.refOvertimeList.refresh();
      this.$emit('refreshData');
    },

    async confirmHoursChief() {
      this.dataValidated = [];
      this.listEmployeeOvertime.forEach(item => {
        if (item.validated === '1' || item.validated === 1) {
          this.dataValidated.push({
            date: JSON.parse(item.date_mark)[0],
            id_justification: item.justification ? item.justification : '',
            state: 'validate',
            hours: item.overtime,
            motive: item.motive,
            employee_id: item.employee_id,
          });
        } else if (item.validated === '2' || item.validated === 2) {
          this.dataValidated.push({
            date: JSON.parse(item.date_mark)[0],
            id_justification: item.justification ? item.justification : '',
            state: 'not validate',
            hours: item.overtime,
            motive: item.motive,
            employee_id: item.employee_id,
          });
        }
      });
      try {
        this.addPreloader();
        const params = {
          data: this.dataValidated,
          employee_id: null,
          user_id: this.currentUser.user_id,
        };
        const response = await DashboardServices.insertApprovedOvertime(params);
        if (response.status === 200) {
          this.showSuccessSwal('Success', 'Overtime Approved Successfully');
          this.dataValidated = [];
          await this.refreshTable();
          this.$emit('refreshTable');
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },

    markCheckboxAll() {
      if (this.validateAll === true) {
        this.listEmployeeOvertime.forEach(item => {
          if (item.justification != null) {
            this.$set(item, 'validated', 1);
          }
        });
        this.notValidateAll = false;
      } else {
        this.listEmployeeOvertime.forEach(item => {
          if (item.justification != null) {
            this.$set(item, 'validated', false);
          }
        });
      }
    },
    markCheckboxAllNotValidate() {
      if (this.notValidateAll === true) {
        this.listEmployeeOvertime.forEach(item => {
          this.$set(item, 'validated', 2);
        });
        this.validateAll = false;
      } else {
        this.listEmployeeOvertime.forEach(item => {
          this.$set(item, 'validated', false);
        });
      }
    },

    addJustification(data) {
      this.dataJustification = data;
      this.employee_id = data.employee_id;
      const [firstElement] = JSON.parse(data.date_mark);
      this.date_overtime = firstElement;
      this.showUpload = true;
      this.actionJustification = 'Add';
    },
    editJustification(data) {
      this.employee_id = data.employee_id;
      this.showUpload = true;
      this.dataJustification = data;
      this.actionJustification = 'Edit';
    },
    showModalMedicalRest(data) {
      this.employee_id = data.employee_id;
      this.showUploadMedicalRest = true;
      this.dataJustification = data;
      this.dataJustification.id = data.justification;
    },

  },
};
</script>

<style>
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
}

.checkbox-container input {
  position: absolute;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.checkbox-container input:checked.blue ~ .checkmark {
  border-color: #007bff;
  background-color: #007bff;
}

.checkbox-container input:checked ~ .checkmark::after {
  content: "";
  position: absolute;
  display: block;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-container input:checked.red ~ .checkmark {
  background-color: #dc3545;
  border-color: #dc3545;
}

.checkbox-container.custom-checkbox input:checked ~ .checkmark::after {
  content: "";
  position: absolute;
  display: block;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.table-scroll-per tfoot tr,
.table-scroll-per tfoot th {
  position: sticky !important;
  bottom: 0 !important;
  z-index: 2;
}
.footer-span.checkbox{
  text-align: left;
}
</style>
