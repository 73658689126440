<template>
  <div>
    <b-modal
      v-model="onControl"
      ref="modal_add_marks"
      size="sm"
      title="Add Marks"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="ligth"
      body-text-variant="dark"
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      no-close-on-backdrop
      class="border-0 border-white"
      @hidden="$emit('hidden')"
    >
      <b-container class="p-1">
        <validation-observer ref="formAddMarks">
          <b-row>
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="employee"
                rules="required"
              >
                <b-form-group label="Select an employee">
                  <v-select
                    id="id"
                    v-model="employeeId"
                    :options="employeesArray"
                    :reduce="(val) => val.id"
                    label="value"
                    :class="errors[0] ? 'border-danger' : ''"
                    :state="errors[0] ? false : null"
                    class="rounded"
                    placeholder="Select an employee ..."
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="date"
                rules="required"
              >
                <b-form-group label="Date">
                  <kendo-datepicker
                    id="date"
                    v-model="date"
                    v-mask="'##/##/####'"
                    :first-day-of-week="1"
                    placeholder="MM/DD/YYYY"
                    :format="'MM/dd/yyyy'"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger' : ''"
                    :min="minDate"
                    :max="maxDate"
                    class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="hours"
                rules="required"
              >
                <b-form-group label="Hours">
                  <kendo-timepicker
                    :format="'HH:mm'"
                    v-mask="'##:##'"
                    v-model="hour"
                    :interval="1"
                    class="w-100 rounded bg-transparent"
                    :state="errors[0] ? false : true"
                    :class="errors[0] ? 'border-danger' : ''"
                    placeholder="HH:mm"
                    style="height: 2.73rem"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </b-container>
      <template #modal-footer>
        <b-button variant="danger" @click="close">Cancel</b-button>
        <b-button variant="primary" @click="insertMarkToEmployee"
          >Save</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DashboardServices from "@/views/assistance/views/dashboard/services/dashboard.services.js";
import moment from "moment";
export default {
  name: "ModalAddMarks",
  data() {
    return {
      onControl: true,
      employeeId: null,
      date: null,
      hour: null,
      employeesArray: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    minDate() {
      const date = new Date();
      return new Date(date.getFullYear(), date.getMonth(), 1);
    },
    maxDate() {
      const date = new Date();
      return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    },
  },
  methods: {
    close() {
      this.onControl = false;
    },
    async getEmployees() {
      try {
        const params = {
          module_id: this.moduleId,
          employee_name: null,
        };
        const data = await DashboardServices.getEmployeeByModule(params);
        if (data.status === 200) {
          this.employeesArray = data.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async insertMarkToEmployee() {
      try {
        const valid = await this.$refs.formAddMarks.validate();
        if (!valid) {
          return;
        }
        this.addPreloader();
        const date = moment(
          `${this.date} ${this.hour}`,
          "MM/DD/YYYY HH:mm"
        ).format("YYYY-MM-DD HH:mm:ss");
        const params = {
          mark_time: date,
          employee_id: this.employeeId,
          created_by: this.currentUser.user_id,
        };
        const { data } = await DashboardServices.insertMarkEmployee(params);
        if (data.status == 1) {
          this.showSuccessSwal();
          this.close();
          this.$emit("refresh");
        }else{
          this.showInfoSwal('This mark already exists')
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
        this.removePreloader();
      }
    },
  },
  async created() {
    await this.getEmployees();
  },
};
</script>