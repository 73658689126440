export default[
    
    {
        key: "device_id",
        label: "Device ID",
        class: "text-center",

    },
    {
        key:"created_at",
        label:"Created At",
        class: "text-center",
    },
    {
        key:"updated_at",
        label:"Updated At",
        class: "text-center",
    }
]