<template>
  <div>
    <!-- modal upload medical rest -->
    <b-modal
      ref="modal_add_hours"
      size="lg"
      title="Add Hour"
      header-bg-variant="primary"
      header-text-variant="light"
      body-bg-variant="ligth"
      body-text-variant="dark"
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      no-close-on-backdrop
      class="border-0 border-white"
      @hidden="$emit('hidden')"
    >
      <b-container class="p-1">
        <validation-observer ref="formAddHour">
          <validation-provider
            v-if="employee_id == null"
            v-slot="{ errors }"
            name="employee"
            rules="required"
          >
            <b-form-group label="Select an employee">
              <v-select
                id="id"
                v-model="employeeId"
                :options="employeesArray"
                :reduce="(val) => val.id"
                label="value"
                :class="errors[0] ? 'border-danger' : ''"
                placeholder="Select an employee ..."
                @input="handleEmployeeSelection()"
              />
              <small class="text-danger">
                {{ errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="date"
            rules="required"
          >
            <b-form-group label="Date">
              <kendo-datepicker
                id="date"
                v-model="hour.date_overtime"
                v-mask="'##/##/####'"
                :first-day-of-week="1"
                placeholder="MM/DD/YYYY"
                :format="'MM/dd/yyyy'"
                :state="errors[0] ? false : null"
                :class="errors[0] ? 'border-danger' : ''"
                :min="minDate"
                class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
              />
              <small class="text-danger">
                {{ errors[0] }}
              </small>
            </b-form-group>
          </validation-provider>
          <b-row>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                name="hours"
                rules="required|max-hours"
              >
                <b-form-group label="Hours">
                  <b-form-input
                    id="hours"
                    v-model="hour.hours"
                    min="0"
                    max="24"
                    type="number"
                    placeholder="Enter hours"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger' : ''"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                name="motive"
                rules="required"
              >
                <b-form-group label="Motive">
                  <!-- select -->
                  <b-form-select
                    v-model="hour.motive"
                    :options="motives"
                    placeholder="Select motive"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger' : ''"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <validation-provider
            v-if="hour.motive == 'Other'"
            v-slot="{ errors }"
            name="motive"
            rules="required"
          >
            <b-form-group label="Description">
              <b-form-textarea
                id="description"
                v-model="hour.description"
                placeholder="Enter Description"
                :state="errors[0] ? false : null"
                :class="errors[0] ? 'border-danger' : ''"
              />
            </b-form-group>
          </validation-provider>
        </validation-observer>

        <b-row class="mt-1">
          <b-col>
            <h4>Absences</h4>
            <hr>
            <template v-if="absenceEmployee.length">
              <div
                class="scroll-container"
                style="height: 250px; overflow-y: auto;"
              >
                <b-list-group>
                  <template v-for="data in absenceEmployee ">
                    <b-list-group-item :key="data.date_mark" v-if="data.missing_hours > 0 && data.overtime_approved<8 ">
                      <b-row>
                        <b-col cols="6">
                          <div
                            class="text-center d-flex justify-content-center align-items-center"
                            style="height: 100%;"
                          >
                            <span>{{ data.date_mark | myDateGlobal }}</span>
                          </div>
                        </b-col>
                        <b-col cols="6">
                          <div
                            class="text-center d-flex justify-content-center align-items-center"
                            style="height: 100%;"
                          >
                            <small class="text-danger">{{ data.missing_hours }} hours missing</small>
                          </div>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </template>
                </b-list-group>
              </div>
            </template>

            <template v-else>
              <b-card
                class="shadow-none"
                :style="
                  !this.isDarkSkin
                    ? 'background-color: #d6eaf8'
                    : 'background-color: #3E3E3F'
                "
              >
                <b-container
                  class="d-flex justify-content-center align-items-center"
                  style="height: 150px"
                >
                  <div
                    class="text-center"
                    style="color: #2980b9"
                  >
                    <tabler-icon
                      icon="MoodHappyIcon"
                      size="40"
                      class="mr-1 mb-2"
                    />
                    <br>
                    <span
                      style="font-size: 25px"
                    >This employee has no absence hours !!!</span>
                  </div>
                </b-container>
              </b-card>
            </template>
          </b-col>
        </b-row>

      </b-container>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-around align-items-center">
          <b-button
            variant="danger"
            @click="closeModal"
          >
            Close
          </b-button>
          <b-button
            variant="primary"
            @click="addHour"
          >
            Save
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import DashboardServices from '@/views/assistance/views/dashboard/services/dashboard.services';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  props: {
    employee_id: null,
    valueFilterMonth: null,
    valueFilterYear: null,
  },
  data() {
    return {
      hour: {
        date_overtime: null,
        motive: 'Remote',
        description: null,
        hours: null,
      },
      motives: [
        { value: 'Remote', text: 'Remote' },
        { value: 'Other', text: 'Other' },
      ],
      // outside employee
      employeeId: null,
      employeesArray: [],
      absenceEmployee: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    minDate() {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      return date;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    if (this.employee_id == null) {
      await this.getEmployees();
    } else {
      await this.getAbsence(this.employee_id);
    }
  },
  mounted() {
    this.toggleModal('modal_add_hours');
  },
  methods: {
    handleEmployeeSelection() {
      this.getAbsence(this.employeeId);
    },
    async getAbsence(id) {
      try {
        this.addPreloader();
        const params = {
          user_id: id,
          filterDay: null,
          filterWeekStart: null,
          filterWeekEnd: null,
          filterMonth: moment().format('M'),
          filterYear: moment().format('YYYY'),
        };
        const data = await DashboardServices.getDetailsAttendance(params);
        if (data.status === 200) {
          const today = moment().startOf('day');
          this.absenceEmployee = data.data.data.filter(item => moment(item.date_mark).isBefore(today, 'day'));
          this.removePreloader();
          return this.absenceEmployee || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit('hidden');
    },
    async getEmployees() {
      try {
        const params = {
          module_id: this.moduleId,
          employee_name: null,
        };
        const data = await DashboardServices.getEmployeeByModule(params);
        if (data.status === 200) {
          this.employeesArray = data.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async addHour() {
      const result = await this.$refs.formAddHour.validate();
      if (!result) return;

      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;

      try {
        const result = await this.$refs.formAddHour.validate();
        if (!result) {
          return;
        }
        this.addPreloader();
        const params = {
          employee_id:
            this.employee_id === null ? this.employeeId : this.employee_id,
          motive: this.hour.motive,
          date_overtime: moment(this.hour.date_overtime, 'MM-DD-YYYY').format(
            'YYYY-MM-DD',
          ),
          description: this.hour.description,
          hours: this.hour.hours,
          user_id: this.currentUser.user_id,
        };
        const { data } = await DashboardServices.addHour(params);
        if (data.status === 200) {
          this.showSuccessSwal('Success', 'Hour registered successfully');
          this.$refs.formAddHour.reset();
          this.$emit('hidden');
          this.closeModal();
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log(error);
      }
    },
  },
};
</script>
