export default [
    {
        key: "employee",
        label: "Employee",
        visible: true,
    },
    {
        key: "show_details",
        label: "",
        class: "text-center",
        visible: true,
    },
    {
        key: "num_marks",
        label: "# Marks",
        visible: true,
        tdClass: "text-center",
    },
    {
        key: "working_hours_valid",
        label: "Worked Hours",
        visible: true,
        tdClass: "text-center",
    },
    {
        key: "working_hours_rounded",
        label: "Rounded Hours",
        visible: true,
        tdClass: "text-center",
    },
    {
        key: "missing_hours",
        label: "Missing Hours",
        visible: true,
    },
    {
        key: "overtime",
        label: "Hours to approve",
        visible: true,
    },
    {
        key: "overtime_pending",
        label: "In process",
        visible: true,
    },
    {
        key: "overtime_approved",
        label: "Approved Hours",
        visible: true,
    },
    {
        key: "hours_holyday",
        label: "Holidays",
        visible: true,
        tdClass: "text-center",
    },
    {
        key: "total",
        label: "Total",
        visible: true,
    },
];
