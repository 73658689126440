<template>
  <b-modal
    ref="modal_details_employee"
    size="xl"
    modal-class="modal-primary"
    centered
    title="Details"
    class="border-0 border-white"
    :hide-footer="true"
    @hidden="closeModal"
  >
    <div style="padding:0 2rem">
      <div
        class="mt-2 mb-2"
        style="border-bottom: 2px solid #0090E7 "
      >
        <b-row class="mb-1">
          <b-col
            cols="2"
          >
            <div style="border-right: 2px solid #0090E7 ">
              <span
                class="h3"
                :class="isDarkSkin ? 'text-white' : 'text-black'"
              >
                {{ nameEmployee }}
              </span>
            </div>
          </b-col>
          <b-col cols="10">
            <div
              v-if="secondFilter.value == 'month'"
              class="mb-1 ml-2"
            >
              <b-form-checkbox
                v-model="typeViewDetail"
                value="calendar"
                name="viewType"
                inline
                class="mr-1 cursor-pointer"
              >
                Calendar
              </b-form-checkbox>
              <b-form-checkbox
                v-model="typeViewDetail"
                value="grid"
                name="viewType"
                inline
                class="cursor-pointer"
              >
                Grid
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>

      <div
        v-if="
          secondFilter.value == 'month' &&
            typeViewDetail == 'calendar'
        "
      >
        <grid-months-marks
          :filters="filtersGrid"
        />
      </div>
      <div v-else>
        <grid-details-marks
          :filters="filtersMark"
        />
      </div>
    </div>

  </b-modal>
</template>
<script>
import gridMonthsMarks from '@/views/assistance/views/dashboard/components/gridMonthsMarks.vue';
import gridDetailsMarks from '@/views/assistance/views/dashboard/components/gridDetailsMarks.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    gridMonthsMarks,
    gridDetailsMarks,
  },
  props: {
    secondFilter: {
      type: Object,
      default: () => {},
    },
    filtersMark: {
      type: Object,
      default: () => {},
    },
    filtersGrid: {
      type: Object,
      default: () => {},
    },
    typeDashboard: {
      type: String,
      require: false,
      default: '',
    },
    stringTypeViewDetail: {
      type: String,
      require: false,
      default: '',
    },
    nameEmployee: {
      type: String,
      require: false,
      default: '',
    },
  },
  data() {
    return {
      showModal: false,
      typeViewDetail: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.toggleModal('modal_details_employee');
    this.showModal = true;
    this.typeViewDetail = this.stringTypeViewDetail;
  },
  methods: {
    typeView(type) {
      this.typeViewDetail = type.toLowerCase();
    },
    closeModal() {
      this.$emit('hidden');
    },
  },
};
</script>
<style>
.border-top-left{
    border-radius: 20px 0 0 0;
}
.border-top-right{
    border-radius: 0 20px 0 0;
}
</style>
