var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal_add_hours",staticClass:"border-0 border-white",attrs:{"size":"lg","title":"Add Hour","header-bg-variant":"primary","header-text-variant":"light","body-bg-variant":"ligth","body-text-variant":"dark","footer-bg-variant":"ligth","footer-text-variant":"dark","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-around align-items-center"},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.closeModal}},[_vm._v(" Close ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addHour}},[_vm._v(" Save ")])],1)]},proxy:true}])},[_c('b-container',{staticClass:"p-1"},[_c('validation-observer',{ref:"formAddHour"},[(_vm.employee_id == null)?_c('validation-provider',{attrs:{"name":"employee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Select an employee"}},[_c('v-select',{class:errors[0] ? 'border-danger' : '',attrs:{"id":"id","options":_vm.employeesArray,"reduce":function (val) { return val.id; },"label":"value","placeholder":"Select an employee ..."},on:{"input":function($event){return _vm.handleEmployeeSelection()}},model:{value:(_vm.employeeId),callback:function ($$v) {_vm.employeeId=$$v},expression:"employeeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,2116374324)}):_vm._e(),_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Date"}},[_c('kendo-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date",class:errors[0] ? 'border-danger' : '',attrs:{"id":"date","first-day-of-week":1,"placeholder":"MM/DD/YYYY","format":'MM/dd/yyyy',"state":errors[0] ? false : null,"min":_vm.minDate},model:{value:(_vm.hour.date_overtime),callback:function ($$v) {_vm.$set(_vm.hour, "date_overtime", $$v)},expression:"hour.date_overtime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"hours","rules":"required|max-hours"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Hours"}},[_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"id":"hours","min":"0","max":"24","type":"number","placeholder":"Enter hours","state":errors[0] ? false : null},model:{value:(_vm.hour.hours),callback:function ($$v) {_vm.$set(_vm.hour, "hours", $$v)},expression:"hour.hours"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"motive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Motive"}},[_c('b-form-select',{class:errors[0] ? 'border-danger' : '',attrs:{"options":_vm.motives,"placeholder":"Select motive","state":errors[0] ? false : null},model:{value:(_vm.hour.motive),callback:function ($$v) {_vm.$set(_vm.hour, "motive", $$v)},expression:"hour.motive"}})],1)]}}])})],1)],1),(_vm.hour.motive == 'Other')?_c('validation-provider',{attrs:{"name":"motive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description"}},[_c('b-form-textarea',{class:errors[0] ? 'border-danger' : '',attrs:{"id":"description","placeholder":"Enter Description","state":errors[0] ? false : null},model:{value:(_vm.hour.description),callback:function ($$v) {_vm.$set(_vm.hour, "description", $$v)},expression:"hour.description"}})],1)]}}],null,false,858386808)}):_vm._e()],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('h4',[_vm._v("Absences")]),_c('hr'),(_vm.absenceEmployee.length)?[_c('div',{staticClass:"scroll-container",staticStyle:{"height":"250px","overflow-y":"auto"}},[_c('b-list-group',[_vm._l((_vm.absenceEmployee),function(data){return [(data.missing_hours > 0 && data.overtime_approved<8 )?_c('b-list-group-item',{key:data.date_mark},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center d-flex justify-content-center align-items-center",staticStyle:{"height":"100%"}},[_c('span',[_vm._v(_vm._s(_vm._f("myDateGlobal")(data.date_mark)))])])]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center d-flex justify-content-center align-items-center",staticStyle:{"height":"100%"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(data.missing_hours)+" hours missing")])])])],1)],1):_vm._e()]})],2)],1)]:[_c('b-card',{staticClass:"shadow-none",style:(!this.isDarkSkin
                  ? 'background-color: #d6eaf8'
                  : 'background-color: #3E3E3F')},[_c('b-container',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"text-center",staticStyle:{"color":"#2980b9"}},[_c('tabler-icon',{staticClass:"mr-1 mb-2",attrs:{"icon":"MoodHappyIcon","size":"40"}}),_c('br'),_c('span',{staticStyle:{"font-size":"25px"}},[_vm._v("This employee has no absence hours !!!")])],1)])],1)]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }