<template>
  <!-- GRAPHICS PIE EMPLOYEES -->
  <div class="pt-2 pb-1 pl-1 pr-1">
    <div
      v-if="!existData"
      style="width: 100%; height: 300px"
      class="d-flex justify-content-center align-items-center bg/-danger"
    >
      <b-list-group
        class="text-center"
        style="min-width:100%;"
      >
        <b-list-group-item>
          <tabler-icon
            size="20"
            icon="XIcon"
            color="red"
            class="mr-1"
          />
          No Birthday
        </b-list-group-item>
        <b-list-group-item>
          <tabler-icon
            size="20"
            icon="QuestionMarkIcon"
            color="orange"
            class="mr-1"
          />
          No Medical Rest
        </b-list-group-item>
        <b-list-group-item>
          <tabler-icon
            size="20"
            icon="TableIcon"
            color="orange"
            class="mr-1"
          />
          No Inconsistencies
        </b-list-group-item>
        <b-list-group-item>
          <tabler-icon
            size="20"
            icon="CrosshairIcon"
            color="purple"
            class="mr-1"
          />
          No Absences
        </b-list-group-item>
      </b-list-group>
    </div>
    <vue-apex-charts
      v-else
      :key="keyChart"
      type="pie"
      height="500"
      :options="chartOptionsPie"
      :series="seriesPie"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import DashboardServices from '@/views/assistance/views/dashboard/services/dashboard.services';

export default {
  components: {
    VueApexCharts,
  },
  props: {
    valueFilterDay: {
      type: Number,
      default: null,
    },
    valueFilterWeekStart: {
      type: String,
      default: null,
    },
    valueFilterWeekEnd: {
      type: String,
      default: null,
    },
    valueFilterMonth: {
      type: String,
      default: null,
    },
    valueFilterYear: {
      type: String,
      default: null,
    },
    valueFilterModule: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      keyChart: 0,
      dataGraphicPie: null,
      seriesPie: [],
      chartOptionsPie: {
        legend: {
          position: 'bottom',
          horizontalAlign: 'start',
          labels: { colors: true },
          width: 180,
          fontFamily: 'Montserrat',
          fontWeight: 300,
          fontSize: '16px',

          markers: {
            radius: 5,
            width: 16,
            height: 16,

          },
        },
        chart: {
          type: 'pie',
        },
        labels: ['Birthdays', 'Medical Rest', 'Inconsistencies', 'Absences'],
        dataLabels: {
          enabled: true,
        },
      },
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isRRhh() {
      return this.moduleId === 17;
    },
    isManagement() {
      return this.moduleId === 16;
    },
    moduleToSendAsProp() {
      switch (true) {
        case this.isRRhh
          && this.$route.name === 'rrhh-chief-attendance-dashboard':
          return this.moduleId;
        case this.isManagement
          && this.$route.name === 'management-chief-attendance-dashboard':
          return this.moduleId;
        case this.isRRhh || this.isManagement:
          return this.valueFilterModule;
        default:
          return this.moduleId;
      }
    },
    existData() {
      let status = false;
      this.seriesPie.forEach(value => {
        if (value > 0) {
          status = true;
        }
      });
      return status;
    },
  },
  async created() {
    await this.getGraphicsPie();
  },
  methods: {
    async getGraphicsPie() {
      try {
        const params = {
          module_id: this.moduleToSendAsProp,
          user_id: null,
          filterDay: this.valueFilterDay,
          filterWeekStart: this.valueFilterWeekStart,
          filterWeekEnd: this.valueFilterWeekEnd,
          filterMonth: this.valueFilterMonth,
          filterYear: this.valueFilterYear,
        };
        const data = await DashboardServices.getIndicatorsAttendance(params);
        this.dataGraphicPie = data.data.data;
        this.generateValuesGraphicsPie(this.dataGraphicPie);
        this.keyChart += 1;
      } catch (error) {
        this.showErrorSwal(error);
        console.log(`graphic pie${error}`);
      }
    },
    generateValuesGraphicsPie(array) {
      try {
        this.seriesPie = [];
        this.seriesPie.push(array[0].birthday_employees);
        this.seriesPie.push(array[0].medical_rest);
        this.seriesPie.push(array[0].employees_incosistencies);
        this.seriesPie.push(array[0].employees_absence);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
</style>
